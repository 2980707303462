import { Box, styled } from '@mui/material'
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { memo } from 'react'
import ScrollTop from 'components/shared/ScrollTop'
import { getCellVisualizationTableColumnDefs } from './metadata'

const TableContainer = styled('div')({
  width: 'calc(100vw - 16px)',
  height: 'calc(100vh - 150px)',
  marginTop: '-22px',
})

const headerHeight = 40

const rowStyle = {
  padding :'0px 50px',
  background: '#FFF',  
  cursor: 'pointer',
  marginTop:'0px'
}

interface Props {
  tableGridApi: GridApi | null
  onGridReady:(event: GridReadyEvent)=>void
  onRowClicked: (event:SelectionChangedEvent)=>void
  handleOpenSession:(sessionId:number)=>void
 
}

const CellVisualizationTable = ({
  tableGridApi,
  onGridReady,
  handleOpenSession,
  onRowClicked,
 }: Props): JSX.Element => {

  const getColumnDefs = () => {
    return getCellVisualizationTableColumnDefs({
      handleOpenSession
    })
  }

  return (
    <>
      <Box sx={{ zIndex: 1 }}>
        <ScrollTop tableGridApi={tableGridApi} />
      </Box>

      
      <TableContainer
        className="ag-theme-v3-cell-vis-table context-menu-ag-grid"
        data-testid="cell-visualization-sessions-table"
       
      >
        <AgGridReact
          onGridSizeChanged={(params: GridSizeChangedEvent) => params.api.sizeColumnsToFit()}
          rowStyle={rowStyle}
          rowSelection="multiple"
          headerHeight={headerHeight}
          onGridReady={onGridReady}
          rowModelType="infinite"
          columnDefs={getColumnDefs()}
          cacheBlockSize={50}
          blockLoadDebounceMillis={2000}
          suppressDragLeaveHidesColumns
          rowBuffer={50}
          onRowClicked={onRowClicked}
        
        />
      </TableContainer>
    </>
  )
}

export default memo(CellVisualizationTable)
