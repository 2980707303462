import EditTextPopover from 'components/shared/EditTextPopover'
import { useState } from 'react'
import { PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'

interface PinnedImagesPopoverProps {
  anchorEl: HTMLElement | null
  pinnedCell: PinnedMorphotype
  onClose: () => void
}
export const EditPinnedNamePopover = ({
  anchorEl,
  pinnedCell,
  onClose,
}: PinnedImagesPopoverProps): JSX.Element => {
  const {
    updatePinnedName,
    cellVisualizations: { pinnedCells },
  } = useCellVisualizationsSlice()
  const [error, setError] = useState('')
  const { id, name } = pinnedCell

  const handleClose = () => {
    setError('')
    onClose()
  }

  const handleNameChangeSave = (newName?: string) => {
    /** Trim off any Unicode whitespace characters for CBS-715
     * Leading or trailing spaces in the schema will break model management */
    const sanitizedName = newName?.trim()
    if (pinnedCells?.some((x) => x.name === sanitizedName)) {
      setError('Name already exists')
    } else {
      updatePinnedName({ pinnedGroupId: id, name: sanitizedName ?? '' })
      handleClose()
    }
  }

  return (
    <EditTextPopover
      anchorEl={anchorEl}
      textBeingEdited={name}
      onSave={handleNameChangeSave}
      onClose={handleClose}
      textFieldProps={{ error: !!error, helperText: error }}
      closeOnSave={false}
    />
  )
}

export default EditPinnedNamePopover
