import { createSvgIcon } from '@mui/material'

const DropdownIcon = createSvgIcon(
  <svg width="14" height="auto" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L6.29289 6.29289C6.68342 6.68342 7.31658 6.68342 7.70711 6.29289L13 1"
      stroke="#5F55D1"
      strokeWidth="1.56"
      strokeLinecap="round"
    />
  </svg>,
  'Dropdown'
)

export default DropdownIcon
