import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import ClockIcon from '@mui/icons-material/AccessTime'

interface DeepcellDateTimePickerProps {
  label: string
  value: Date | null
  onChange: (date: Date) => void
  dataTestId?: string
  disableFuture?: boolean
}

export const DeepcellDateTimePicker = ({
  label,
  value,
  onChange,
  dataTestId,
  disableFuture,
}: DeepcellDateTimePickerProps): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        // https://www.unicode.org/reports/tr35/tr35-dates.html#table-date-field-symbol-table
        inputFormat="MM-dd-yyyy HH:mm"
        label={label}
        value={value ?? null}
        components={{
          OpenPickerIcon: ClockIcon,
        }}
        onChange={(data: Date | null) => onChange(data as Date)}
        renderInput={(params) => (
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            placeholder=""
            fullWidth
            data-testid={dataTestId}
            {...params}
          />
        )}
        showToolbar
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  )
}

export default DeepcellDateTimePicker
