import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CellVisualizationsState } from './CellVisualizationsSlice';

type CVSKey = keyof CellVisualizationsState



export interface SessionSearchState {
    advancedSearchEnabled?:boolean
    versionConfigFields?: CVSKey[]; 
    runIds?: string; 
    sessionName?: string; 
    email?: string; 
    classifierName?: string; 
    sampleType?: string; 
    sampleID?: string; 
    keyword?: string[]; 
}

export const SessionSearchFilterInitialState: SessionSearchState = {
    advancedSearchEnabled: undefined,
    versionConfigFields:undefined,
    sessionName:undefined,
    email:undefined,
    keyword: [],
    classifierName:undefined,
    runIds: undefined,
    sampleID: undefined,
   
}

export const sessionSearchFilterSlice = createSlice({
    name: 'sessionFilterSearch',
    initialState: SessionSearchFilterInitialState,
    reducers: {
        update: (_state, action: PayloadAction<SessionSearchState>) => action.payload,
        clear: () => SessionSearchFilterInitialState,
    },
})

export const SessionSearchFilterActions = sessionSearchFilterSlice.actions
export const SessionSearchFilterReducer = sessionSearchFilterSlice.reducer

