import * as ENVIRONMENTS from 'constants/environments'
import { Box, ListItem, Stack, Tooltip, styled } from '@mui/material'
import ChartIcon from 'components/shared/icons/ChartIcon'
import DistributionToggleIcon from 'components/shared/icons/DistributionToggleIcon'
import DownloadToggleIcon from 'components/shared/icons/DownloadToggleIcon'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useState } from 'react'
import useAuthTokens from 'utils/useAuthTokens'
import DropdownModal from './DropdownModal'
import PrimaryButton from './PrimaryButton'

interface RunDetailsActionBarProps {
  handleStartAnalysis: VoidFunction
  handleBrowseCells: VoidFunction
  onDownload: VoidFunction
  runIdString: string | undefined
  instrumentIdString?: string
  runStartTime?: number
  runEndTime?: number | undefined
  runStatus?: string
}

const ActionBarContainer = styled(Stack)({
  borderRadius: '4px',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
  padding: '8px 6px',
  height: '48px',
})

const IconSx = {
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  '&:hover > rect': { fill: '#a1eabc' },
}

export const CLASS_DISTRIBUTION_URL =
  'https://app.mode.com/deepcell/reports/7b023439388c?param_run_id_param='
export const LABEL_SUMMARY_URL =
  'https://app.mode.com/deepcell/reports/3565fff6cd73?param_run_id_param='
export const OOF_SCORE_URL = 'https://app.mode.com/deepcell/reports/313d3d2fda59?param_RunId='

const RunDetailsActionBar = ({
  handleStartAnalysis,
  handleBrowseCells,
  onDownload,
  runIdString,
  instrumentIdString,
  runStartTime,
  runEndTime,
  runStatus,
}: RunDetailsActionBarProps): JSX.Element => {
  const showStartAnalysis = useFlagCondition('runsStartAnalysisEnabled')
  const { isInternalUser } = useAuthTokens()

  // check to see if a run was imported into Axon - runs outside of REM-I
  const importedRun = runStatus === 'Imported'

  const modelsV3ApiEnabled = useFlagCondition('modelsV3ApiEnabled')

  // check to see if only one run selected
  const onlyOneRunEnabled = runIdString && runIdString.indexOf(',') <= -1

  // determines the appropriate influx db environment based on the environment in which the App is running.
  const influxDBEnv =
    process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION ? 'InfluxDB' : 'InfluxDB-Staging'
  const toTime = runEndTime || 'now'
  const GRAFANA_URL = `https://grafana.deepcellbio.com/d/SxtAYdjnk/instrument-metrics?orgId=1&var-Controller=${instrumentIdString}&from=${runStartTime}&to=${toTime}&var-Source=${influxDBEnv}`

  const GET_EMBEDDINGS_URL = modelsV3ApiEnabled
    ? 'https://app.mode.com/deepcell/reports/f140de791fe1?param_run_id_param='
    : 'https://app.mode.com/deepcell/reports/aa1634064be5?param_run_id_param='

  const [modalState, setModalState] = useState({
    download: false,
    distribution: false,
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleDistribution = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setModalState((prev) => ({
      distribution: !prev.distribution,
      download: false,
    }))
  }

  const handleDownload = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setModalState((prev) => ({
      distribution: false,
      download: !prev.download,
    }))
  }

  const StyledListItem = styled(ListItem)({
    color: '#000',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      background: '#E6E4FF',
    },
    '&:active': {
      color: '#5F55D1',
      background: '#E6E4FF',
    },
  })

  const StyledLink = styled('a')({
    textDecoration: 'none',
    color: 'inherit',
  })

  return (
    <>
      <ActionBarContainer direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <PrimaryButton onClick={handleBrowseCells}>Browse Cells</PrimaryButton>
          {showStartAnalysis && (
            <PrimaryButton onClick={handleStartAnalysis}>Start Analysis</PrimaryButton>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          {onlyOneRunEnabled && isInternalUser && !importedRun && (
            <Tooltip title="Run metrics in Grafana">
              <StyledLink target="_blank" href={GRAFANA_URL}>
                <ChartIcon sx={IconSx} />
              </StyledLink>
            </Tooltip>
          )}
          {!importedRun && isInternalUser && (
            <Box onClick={handleDistribution}>
              <Tooltip title="See distributions">
                <DistributionToggleIcon sx={IconSx} />
              </Tooltip>
            </Box>
          )}
          <Box onClick={handleDownload}>
            <Tooltip title="Download run report">
              <DownloadToggleIcon sx={IconSx} />
            </Tooltip>
          </Box>
        </Stack>
      </ActionBarContainer>

      {/* Dropdown modals for download and distribution */}
      <DropdownModal
        placement="bottom-start"
        disablePortal={false}
        transition
        anchorEl={anchorEl}
        open={modalState.download}
        onClickAway={() => setModalState({ ...modalState, download: false })}
      >
        <Box>
          <StyledListItem>
            <StyledLink target="_blank" href={GET_EMBEDDINGS_URL + runIdString}>
              Embeddings
            </StyledLink>
          </StyledListItem>
          <StyledListItem onClick={onDownload}>Run Data CSV</StyledListItem>
        </Box>
      </DropdownModal>

      <DropdownModal
        placement="bottom-start"
        disablePortal={false}
        transition
        anchorEl={anchorEl}
        open={modalState.distribution}
        onClickAway={() => setModalState({ ...modalState, distribution: false })}
      >
        <Box>
          <StyledListItem>
            <StyledLink target="_blank" href={CLASS_DISTRIBUTION_URL + runIdString}>
              See class distributions
            </StyledLink>
          </StyledListItem>

          <StyledListItem>
            <StyledLink target="_blank" href={LABEL_SUMMARY_URL + runIdString}>
              See label distributions
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink target="_blank" href={OOF_SCORE_URL + runIdString}>
              See OOF distribution
            </StyledLink>
          </StyledListItem>
        </Box>
      </DropdownModal>
    </>
  )
}

export default RunDetailsActionBar
