import { DeepcellPrimaryButton } from 'components/shared'
import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useWalkthroughSlice from 'redux/slices/hooks/useWalkthroughSlice'
import useWalkthroughSteps from './useWalkthroughSteps'
import Walkthrough, { StepUpdate, WalkthroughProps } from './Walkthrough'

const guideVisitedKey = 'guide-visited'
export const dataVizWalkthroughButtonId = 'dataVizWalkthroughButtonId'

export const DataVizWalkthroughButton = (): JSX.Element => {
  const {
    setShow,
    walkthrough: { show },
  } = useWalkthroughSlice()
  const [walkthroughProps, setWalkthroughProps] = useState<WalkthroughProps>({})
  const resetWalkthrough = () => setWalkthroughProps({})
  const { setShowMorphotypes, setShowCompare, setShowColorBy } = useCellVisualizationsSlice()
  const steps = useWalkthroughSteps()
  const eventsManager = useEventsManager()

  // some steps can have their targets changed before moving to the next step
  useEffect(() => {
    const differentTargetExists = steps.find(
      (s, i) => walkthroughProps.steps && walkthroughProps.steps[i].target !== s.target
    )
    if (differentTargetExists) {
      setWalkthroughProps((curr) => ({ ...curr, steps }))
    }
  }, [steps, walkthroughProps.steps])

  useEffect(() => {
    if (show === false) {
      resetWalkthrough()
      setShow(undefined)
    }
  }, [setShow, show])

  const onWalkthroughUpdate = ({ action, fromIndex, toIndex }: StepUpdate) => {
    if (action === 'next') {
      if (toIndex) {
        setWalkthroughProps((prev) => ({ ...prev, index: toIndex }))
      } else if (fromIndex + 1 >= (walkthroughProps.steps?.length ?? Infinity)) {
        // analytics
        eventsManager.sendGuideWalkthroughEvent(false)
        // clicked Next on the last step
        resetWalkthrough()
      } else {
        setWalkthroughProps((prev) => ({ ...prev, index: fromIndex + 1 }))
      }
    } else if (action === 'skip') {
      const titleWhenSkipped =
        (walkthroughProps.steps &&
          walkthroughProps.steps.length > 0 &&
          walkthroughProps.steps[fromIndex].name) ||
        ''
      // analytics
      eventsManager.sendGuideWalkthroughEvent(true, titleWhenSkipped)
      resetWalkthrough()
    }
  }

  const handleGuideClick = () => {
    // close drawers
    setShowMorphotypes(false)
    setShowCompare(false)
    setShowColorBy(false)

    localStorage.setItem(guideVisitedKey, guideVisitedKey)

    setWalkthroughProps({
      onUpdate: onWalkthroughUpdate,
      index: 0,
      show: true,
      steps,
    })
  }

  useMount(() => {
    const guideVisited = localStorage.getItem(guideVisitedKey)
    if (guideVisited !== guideVisitedKey) {
      handleGuideClick()
    }
  })

  return (
    <>
      <DeepcellPrimaryButton id={dataVizWalkthroughButtonId} contained onClick={handleGuideClick}>
        Guide
      </DeepcellPrimaryButton>
      <Walkthrough {...walkthroughProps} />
    </>
  )
}

export default DataVizWalkthroughButton
