import { RowClassParams } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

const rowStyle = {
  borderRadius: '4px',
  background: '#F6F6F6',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
}

interface AgGridRunDetailsTableProps extends AgGridReactProps {
  headerHeight?: number | undefined
  rowData: unknown[]
  testId: string | undefined
  rowGap?: number | undefined
  customClass?: string | undefined
}

const AgGridSessionDetailsTable = ({
  headerHeight = 25,
  rowData,
  testId = 'ag-grid',
  rowGap = 4,
  customClass = '',
  ...rest
}: AgGridRunDetailsTableProps): JSX.Element => {
  const rowLength = rowData?.length
  const containerHeight = rowLength * 25 + (rowLength - 1) * rowGap + 20 + headerHeight

  return (
    <div
      style={{ height: `${containerHeight}px`, width: '100%' }}
      className={`ag-theme-v3 ${customClass}`}
      data-testid={testId}
    >
      <AgGridReact
        headerHeight={headerHeight}
        rowStyle={rowStyle}
        getRowStyle={(params: RowClassParams) => {
          const { rowIndex } = params.node
          return {
            marginTop: !rowIndex ? '0' : `${rowGap * rowIndex}px`,
          }
        }}
        rowData={rowData}
        {...rest}
      />
    </div>
  )
}

export default AgGridSessionDetailsTable
