import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { QueryClient } from 'react-query'
import { getCellImage } from 'utils/api'
import { useLocalStorage } from 'react-use'
import { useNotificationSlice } from 'redux/slices/hooks'
import convertToTiff from './utils'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})

type MorphotypeGroup = {
  groupName: string
  groupId: number
  cellIds: string[]
}
interface CellIdsDownloaderConfigProps {
  type?: 'png' | 'tiff'
  groups: MorphotypeGroup[]
  zippedFileName?: string
  downloadLimit: number
  timeFrame: number
}
interface CellIdsDownloaderReturn {
  handleDownloadZip: () => Promise<void>
  loading: boolean
}
const getCellIdImage = async (cellId: Record<string, number>): Promise<string> => {
  const { cellNumber, cellTime, instrumentId, cellFrame } = cellId
  const cellIdParams = { number: cellNumber, time: cellTime, instrumentId, frame: cellFrame }
  const responseCellImage = await queryClient.fetchQuery(
    ['getCellImageById', cellIdParams],
    getCellImage,
    {
      staleTime: 0,
    }
  )
  return responseCellImage
}

const useCellIdsBatchDownloader = (
  key: string,
  configOptions: CellIdsDownloaderConfigProps
): CellIdsDownloaderReturn => {
  const zip = new JSZip()
  const [loading, setLoading] = useState<boolean>(false)
  const [spamDetection, setSpamDetection] = useLocalStorage(key, {
    count: 0,
    started_at: 0,
  })
  const {
    type = 'png',
    groups,
    zippedFileName = '',
    downloadLimit,
    timeFrame, // In-minutes
  } = configOptions
  const [timeLimit, setTimeLimit] = useState(0)
  const { showError } = useNotificationSlice()
  async function downloadImages() {
    try {
      for (let i = 0; i < groups.length; i += 1) {
        const group = groups[i]
        const { groupName, cellIds } = group
        const imagePromises = []

        for (let j = 0; j < cellIds.length; j += 1) {
          const cellId = cellIds[j]
          const [cellTime, instrumentId, cellNumber, cellFrame] = cellId.split('_').map(Number)
          const cellIdMeta = {
            cellTime,
            cellNumber,
            instrumentId,
            cellFrame,
          }
          const imagePromise = getCellIdImage(cellIdMeta).then((base64Image) => ({
            fileName: `${cellTime} ${cellNumber} ${instrumentId}`,
            base64Image: base64Image || '',
          }))
          imagePromises.push(imagePromise)
        }

        // eslint-disable-next-line no-await-in-loop
        const imageData = await Promise.all(imagePromises)

        const zipFolder = zip.folder(groupName)
        for (let k = 0; k < imageData.length; k += 1) {
          const { fileName, base64Image } = imageData[k]
          const base64String = base64Image.split(',')[1]
          if (type === 'png') {
            zipFolder?.file(`${fileName}.${type}`, base64String, { base64: true })
          } else {
            zipFolder?.file(`${fileName}.${type}`, convertToTiff(base64String))
          }
        }
      }

      const timeStamp = Date.now().toString()
      const fileName = zippedFileName ? `${zippedFileName}-${timeStamp}` : `${timeStamp}`
      const blob = await zip.generateAsync({ type: 'blob' })
      saveAs(blob, `${fileName}.zip`)
    } catch (error) {
      console.error('Error downloading images:', error)
    }
  }

  const handleDownloadZip = async () => {
    setLoading(true)
    try {
      if (!spamDetection) {
        return
      }
      const startTime = Date.now()
      const validRequest =
        spamDetection.count < downloadLimit && spamDetection.started_at < timeLimit
      const maxRequestExceeded =
        spamDetection.count >= downloadLimit && spamDetection.started_at < timeLimit
      const maxTimeExceeded = spamDetection.started_at > timeLimit
      if (!spamDetection?.count) {
        setTimeLimit(startTime + timeFrame * 60 * 1_000)
        await downloadImages()
        setSpamDetection({
          count: spamDetection.count + 1,
          started_at: startTime,
        })
      } else if (validRequest) {
        await downloadImages()
        setSpamDetection({
          count: spamDetection.count + 1,
          started_at: startTime,
        })
      } else if (maxRequestExceeded) {
        throw new Error('Maximum download exceeded')
      } else if (maxTimeExceeded) {
        setTimeLimit(startTime + timeFrame * 60 * 1_000)
        await downloadImages()
        setSpamDetection({
          count: 1,
          started_at: startTime,
        })
      }
    } catch (err) {
      if (err instanceof Error) {
        showError(`Error while downloading/archiving images: ${err.message}`)
      }
    } finally {
      setLoading(false)
    }
  }
  return { handleDownloadZip, loading }
}
export default useCellIdsBatchDownloader
