import { createSvgIcon } from '@mui/material'

const WarningIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.27 13.575L21.075 20.85H19.65L19.455 13.575H21.27ZM20.415 24.105C20.105 24.105 19.845 24 19.635 23.79C19.425 23.58 19.32 23.32 19.32 23.01C19.32 22.7 19.425 22.44 19.635 22.23C19.845 22.02 20.105 21.915 20.415 21.915C20.715 21.915 20.97 22.02 21.18 22.23C21.39 22.44 21.495 22.7 21.495 23.01C21.495 23.32 21.39 23.58 21.18 23.79C20.97 24 20.715 24.105 20.415 24.105Z"
      fill="#FF3653"
    />
    <path
      d="M19.134 1.5C19.5189 0.833336 20.4811 0.833333 20.866 1.5L36.4545 28.5C36.8394 29.1667 36.3583 30 35.5885 30H4.41154C3.64174 30 3.16062 29.1667 3.54552 28.5L19.134 1.5Z"
      stroke="#FF3653"
      strokeWidth="2"
    />
  </svg>,
  'Warning'
)

export default WarningIcon
