import { Box, BoxProps, Checkbox, Skeleton, styled } from '@mui/material'
import CheckedIcon from 'components/shared/icons/CheckedIcon'
import { useEffect, useMemo, useState } from 'react'
import { QueryStatus } from 'react-query'
import { CellsByGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { PinnedMorphotype } from 'redux/slices/types'
import { useCellImageUrlFromCellId } from './tsv/utils'

const imageSize = 72
interface ActionPlotCellImageProps {
  pinnedGroupId?: number
  cellId: string
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  wrapperProps?: BoxProps
  handleStatusChange?(status?: QueryStatus): void
  /**
   * If width/height is not provided, use the default height/width of 72
   * @default true
   */
  revertToDefaultDimensions?: boolean
}

const UncheckedOverlay = styled(Box)({
  background: 'white',
  width: '100%',
  height: '100%',
  zIndex: 1,
  position: 'absolute',
  opacity: '0.5',
})

function findGroupName(groupId: number, cells: PinnedMorphotype[] | undefined): string | undefined {
  const activeGroup = cells?.find((group) => {
    return group.id === groupId
  })

  return activeGroup?.name
}

export const ActionPlotCellImage = ({
  pinnedGroupId,
  cellId,
  imgProps,
  wrapperProps,
  handleStatusChange,
  revertToDefaultDimensions = true,
}: ActionPlotCellImageProps): JSX.Element => {
  const { imageDataUrl, status } = useCellImageUrlFromCellId(cellId)
  const [activeGroup, setActiveGroup] = useState<CellsByGroup | undefined>()
  const [pinnedGroupName, setPinnedGroupName] = useState<string>('')

  const {
    appendCellToExport,
    cellVisualizations: { exportCellMorphotype, pinnedCells },
  } = useCellVisualizationsSlice()

  useEffect(() => {
    const currentGroup = exportCellMorphotype?.find(
      (morphotype) => morphotype.groupId === pinnedGroupId
    )

    if (currentGroup) {
      setActiveGroup(currentGroup)
    } else {
      setActiveGroup(undefined)
    }
  }, [exportCellMorphotype, pinnedGroupId])

  useEffect(() => {
    if (handleStatusChange) handleStatusChange(status)
  }, [handleStatusChange, status])

  const handleCellClicked = () => {
    appendCellToExport({
      groupId: pinnedGroupId as number,
      cellId,
      groupName: pinnedGroupName,
    })
  }

  const defaultImageSize = revertToDefaultDimensions ? imageSize : undefined

  const showOverlay = useMemo(() => {
    if (activeGroup?.cellIds.length) {
      return activeGroup?.cellIds.length > 0
    }
    return false
  }, [activeGroup])

  const showChecked = useMemo(() => {
    return activeGroup?.cellIds.includes(cellId)
  }, [cellId, activeGroup])

  useEffect(() => {
    if (pinnedGroupId) {
      const groupName = findGroupName(pinnedGroupId, pinnedCells)
      if (groupName) {
        setPinnedGroupName(groupName)
      }
    }
  }, [pinnedCells, pinnedGroupId])

  return (
    <Box component="span" sx={{ display: 'inline-flex', p: '2px' }} {...wrapperProps}>
      {imageDataUrl ? (
        <Box sx={{ position: 'relative', overflow: 'hidden' }} onClick={() => handleCellClicked()}>
          {showChecked ? (
            <Checkbox
              disableTouchRipple
              checkedIcon={<CheckedIcon inheritViewBox sx={{ width: '22px', height: '22px' }} />}
              sx={{ p: 0, position: 'absolute', zIndex: '2' }}
              checked
            />
          ) : (
            showOverlay && <UncheckedOverlay />
          )}
          <img
            data-testid="ActionPlotCellImageImgElement"
            src={imageDataUrl}
            width={imgProps?.width ?? defaultImageSize}
            alt=""
            {...imgProps}
          />
        </Box>
      ) : (
        <Skeleton
          data-testid="ActionPlotCellImageSkeleton"
          variant="rectangular"
          width={imgProps?.width ?? defaultImageSize}
          height={imgProps?.height ?? defaultImageSize}
          style={imgProps?.style}
        />
      )}
    </Box>
  )
}

export default ActionPlotCellImage
