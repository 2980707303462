import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export enum DateOperator {
  After,
  Before,
  Between,
  On,
}

/**
 * * Started == false, and Complete == true
 * * This maps to the db field "stopped"
 */
export enum RunSearchStatus {
  Started = 0,
  Complete = 1,
}

// Current List of possible stop reasons that can be set by the instrument.
export const RunSearchStopReason: string[] = [
  'ManualStop',
  'ElapsedTime',
  'ImagedCount',
  'SortedCount',
  'WellCounts',
  'Metric',
  'Error',
  'Unknown',
]

export interface RunSearchFilterState {
  advancedSearchEnabled?: boolean
  keyword?: string
  runIds?: string
  runDateStart?: Date
  runDateEnd?: Date
  runDateOperator?: DateOperator
  sampleID?: string
  operator?: string
  status?: RunSearchStatus
  stopReason?: string
}

// I'm explicitly defining each property so that I can iterate over the keys
export const RunSearchFilterInitialState: RunSearchFilterState = {
  advancedSearchEnabled: undefined,
  keyword: undefined,
  runIds: undefined,
  runDateStart: undefined,
  runDateEnd: undefined,
  runDateOperator: undefined,
  sampleID: undefined,
  operator: undefined,
  status: undefined,
  stopReason: undefined,
}

export const runSearchFilterSlice = createSlice({
  name: 'runSearchFilter',
  initialState: RunSearchFilterInitialState,
  reducers: {
    update: (_state, action: PayloadAction<RunSearchFilterState>) => action.payload,
    clear: () => RunSearchFilterInitialState,
  },
})

export const RunSearchFilterActions = runSearchFilterSlice.actions
export const RunSearchFilterReducer = runSearchFilterSlice.reducer

export const runSearchFilterState = (state: RootState): RunSearchFilterState =>
  state.runSearchFilter
