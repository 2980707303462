/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { datadogRum } from '@datadog/browser-rum'
import '@fontsource/poppins'
import { LicenseManager } from 'ag-grid-enterprise'
import { createRoot } from 'react-dom/client'
import './ag-theme-deepcell.css'
import App from './components/App'
import { AG_GRID_LICENSE_KEY, APM_CONFIG } from './config'
import './index.css'
import { API_URL } from './utils/api'

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
// Disabled due to adding in Mock Service Worker:
// https://mswjs.io/docs/getting-started/integrate/browser#create-react-app-version-3
//
// If we need the app to work offline later, review how to use this
// serviceWorker.unregister()

// Datadog
datadogRum.init({
  applicationId: APM_CONFIG.datadog.applicationId,
  clientToken: APM_CONFIG.datadog.clientToken,
  site: APM_CONFIG.datadog.URL,
  service: APM_CONFIG.serviceName,
  env: process.env.REACT_APP_ENV,
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [API_URL], // connect distributed tracing to the API
})
datadogRum.startSessionReplayRecording()
