import { Typography } from '@mui/material'
import { useSearchCells } from 'components/cell-browsing/cellSearchHelpers'
import useCellBrowsingQueryParams from 'components/cell-browsing/useCellBrowsingQueryParams'
import SectionCard from 'components/shared/SectionCard'
import useRunDetailsSlice from 'redux/slices/hooks/useRunDetailsSlice'
import CellImagesGallery from './CellImagesGallery'

const ImagesSection = (): JSX.Element => {
  const { search: searchOptions } = useCellBrowsingQueryParams()
  const { runDetails } = useRunDetailsSlice()

  const { result: cellData, isLoading } = useSearchCells({
    ...searchOptions,
    page: runDetails.pageCount,
  })

  return (
    <SectionCard title="Images">
      {cellData?.count ? (
        <CellImagesGallery cellData={cellData} />
      ) : (
        <Typography>
          {isLoading && 'Loading cell images...'}
          {!isLoading && "Didn't capture any cell images as part of this run."}
        </Typography>
      )}
    </SectionCard>
  )
}

export default ImagesSection
