import { createSlice } from '@reduxjs/toolkit'

export interface ErrorState {
  errored: boolean
}

export const errorInitialState: ErrorState = {
  errored: false,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState: errorInitialState,
  reducers: {
    triggerError: (state) => {
      state.errored = true
    },
    clearError: (state) => {
      state.errored = false
    },
  },
})

export const ErrorActions = errorSlice.actions
export const ErrorReducer = errorSlice.reducer
