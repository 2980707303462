import { styled } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

interface OutlinedVariant {
  /**
   * The variant to use.
   *
   * Variant type: outlined
   *
   * Refer: https://mui.com/material-ui/react-button/#outlined-button
   */
  outlined: boolean
  contained?: never
}
interface ContainedVariant {
  outlined?: never
  /**
   * The variant to use.
   *
   * Variant type: contained
   *
   * Refer: https://mui.com/material-ui/react-button/#contained-button
   */
  contained: boolean
}

const StyledContainedButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.success.main,
  padding: '10px 25px',
  gap: '10px',
  background: theme.palette.secondary.main,
  border: `2px solid ${theme.palette.success.main}`,
  borderRadius: '4px',
  '&:hover': {
    background: theme.palette.secondary.main,
    boxShadow: '0px 0px 10px #000000',
  },
}))

const StyledOutlineButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.success.main,
  padding: '10px 25px',
  gap: '10px',
  background: theme.palette.background.default,
  border: `2px solid ${theme.palette.success.main}`,
  borderRadius: '4px',
  '&:hover': {
    border: `2px solid ${theme.palette.success.main}`,
    background: theme.palette.background.default,
    boxShadow: '0px 0px 10px #000000',
  },
}))

type DeepcellSecondaryButtonProps = ButtonProps & (OutlinedVariant | ContainedVariant)

export const DeepcellSecondaryButton = ({
  children,
  outlined,
  contained,
  ...props
}: DeepcellSecondaryButtonProps): JSX.Element => {
  if (contained) {
    return (
      <StyledContainedButton {...props} disableRipple color="secondary" variant="contained">
        {children}
      </StyledContainedButton>
    )
  }
  if (outlined) {
    return (
      <StyledOutlineButton {...props} disableRipple color="secondary" variant="outlined">
        {children}
      </StyledOutlineButton>
    )
  }
  return <></>
}

export default DeepcellSecondaryButton
