import useFlagCondition from 'components/shared/useFlagCondition'
import useFlags from 'components/shared/useFlags'
import { ColorBar } from 'plotly.js'
import { useMemo } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CellPlotData } from 'redux/slices/types'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import { coalesce } from '../utils'
import getMarkerSize from './getMarkerSize'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'

export type BasePlotParams = {
  x: number
  y: number
  originalX: number
  originalY: number
  name: string
  cellId: string
  color: string
}

/**
 * Returns a function that will give you the start of a plot based on global hook values
 */
export const useBaseCellPlotData = (): {
  getBaseCellPlotData: (params: BasePlotParams) => CellPlotData
} => {
  const { isPreFilter } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const demoEnabled = useFlagCondition('demoEnabled')
  const showPreFilter = isPreFilter && preFilterEnabled && !demoEnabled

  const { cellVisualizationsContinousColorScale: continuousColorScale } = useFlags()
  const continuousReverseScale = useFlagCondition('cellVisualizationsReverseContinuousColorScale')

  const { cellVisualizationsDensityColorScale: densityColorScale } = useFlags()
  const densityReverseScale = useFlagCondition('cellVisualizationsReverseDensityColorScale')

  const { height: panelHeight } = usePanelHeight()
  const {
    cellVisualizations: { selectedCellInfoGroupName, cellInfoGroups, plotDisplayType, cellsData },
  } = useCellVisualizationsSlice()
  const markerSize = useMemo(() => getMarkerSize(cellsData?.length || 1), [cellsData?.length])

  const isDensity = plotDisplayType === 'density'
  const { isContinuous } = cellInfoGroups[selectedCellInfoGroupName] ?? {}

  /**
   * Gets the base Plotly plot data for a list of cells
   *
   * @param x Array of x coordinates to use.  These may be translated for multi-plot view from the original UMAP coordinates
   * @param y Array of y coordinates to use.  These may be translated for multi-plot view from the original UMAP coordinates
   * @param originalX Array of original X coordinates from the UMAP
   * @param originalY Array of original Y coordinates from the UMAP
   * @param name Name of the plot (e.g. Leiden cluster, category)
   * @param cellId Array of unique Cell Image ID values
   * @param color Color to use
   */
  const getBaseCellPlotData = ({
    x,
    y,
    originalX,
    originalY,
    name,
    cellId,
    color,
  }: BasePlotParams) => {
    const reversescale = coalesce(
      [isContinuous, continuousReverseScale],
      [isDensity, densityReverseScale]
    ) as boolean

    const colorscale = coalesce(
      [isContinuous, continuousColorScale],
      [isDensity, densityColorScale]
    ) as string

    const colorbar =
      isDensity && !showPreFilter
        ? ({
            xref: 'paper',
            x: -0.05,
            lenmode: 'pixels',
            len: panelHeight - 100,
            ticks: 'outside',
          } as Partial<ColorBar>)
        : undefined

    return {
      type: 'scattergl',
      mode: 'markers',
      hoverinfo: 'none',
      opacity: 1,
      // @ts-ignore: This field is supported but not in the type definition for Plotly
      // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-unselected
      // By default, we fade out unselected points to indicate selection.  This makes it harder to make the next selection.
      // Instead, fade out the selected points slightly and make them bigger.
      unselected: {
        marker: {
          opacity: 1,
        },
      },
      // @ts-ignore: This field is supported but not in the type definition for Plotly
      // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-selected
      selected: {
        marker: {
          size: markerSize * 1.7,
          opacity: 0.75,
        },
      },
      x: [x],
      y: [y],
      originalX: [originalX],
      originalY: [originalY],
      plotDatum: [{ x, y, cellId }],
      cellIds: [cellId],
      name,
      marker: {
        size: markerSize,
        color: [color],
        colorscale,
        showscale: false,
        reversescale,
        colorbar,
      },
    } as CellPlotData
  }

  return { getBaseCellPlotData }
}

export default useBaseCellPlotData
