import { Card, CardContent, PopoverProps, Stack, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import {
    DeepcellMultiInput,
    DeepcellPrimaryButton,
    DeepcellTextField,
} from 'components/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import { ChangeEvent } from 'react'
import { SessionSearchFilterInitialState, SessionSearchState } from 'redux/slices'
import useSessionSearchFilterSlice from 'redux/slices/hooks/useSessionSearchFilter'


interface AdvancedSessionSearchFilterPopoverProps {
    open: PopoverProps['open']
    anchorEl?: PopoverProps['anchorEl']
    handleCancel?: (clear?: boolean) => void
    handleSearch?: () => void
}

const StyledCardContent = styled(CardContent)({
    width: '600px',
    padding: '0px',
})


export const AdvancedSessionSearchFilterPopover = ({
    open,
    anchorEl,
    handleCancel: handleCancelProp,
    handleSearch: handleSearchProp,
}: AdvancedSessionSearchFilterPopoverProps): JSX.Element => {
   const {sessionSearchFilter,update}=useSessionSearchFilterSlice()
     const handleChange =
        (key: keyof SessionSearchState) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
            const newSessionSearchFilter = {
                ...sessionSearchFilter,
                [key]: e.target.value,
            }
            update(newSessionSearchFilter)
        }

    const handleCancel = (clear = true) => {
        
        if (clear) {
            update({
                ...SessionSearchFilterInitialState,
                keyword: sessionSearchFilter.keyword,
            })
        }
        if (handleCancelProp) handleCancelProp(clear)
    }

    const handleSearch = () => {
        update({
            ...sessionSearchFilter,
            advancedSearchEnabled: true,
        })
        if (handleSearchProp) handleSearchProp()
    }

    const updateField = (field: keyof SessionSearchState, newVal: string) => {
        update({
            ...sessionSearchFilter,
            [field]: newVal,
        });
    };

    return (
        <DeepcellPopover
            open={open}
            onClose={() => handleCancel(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Card sx={{ border: 'none' }} variant="outlined">
                <StyledCardContent>
                    <Stack spacing={2}>
                        <DeepcellTextField
                            fullWidth
                            label="Contains Keywords"
                            placeholder="Search for keywords in any fields"
                            value={sessionSearchFilter.keyword}
                            onChange={handleChange('keyword')}
                        />
                        <DeepcellTextField
                            fullWidth
                            label="Session Name"
                            value={sessionSearchFilter.sessionName}
                            placeholder="Enter Session name"
                            onChange={handleChange('sessionName')}
                        />
                         
                        <DeepcellMultiInput
                            value={
                                sessionSearchFilter.runIds ? sessionSearchFilter.runIds.split(/\s+/) : []
                            }
                            updateValueState={(newVal) => updateField('runIds', newVal)}
                            label="Run Ids"
                            inputProps={{
                                placeholder: 'Enter Run Ids',
                            }}
                        
                        />

                        <DeepcellTextField
                            fullWidth
                            label="Creator Email"
                            value={sessionSearchFilter.email}
                            placeholder="Enter email address"
                            onChange={handleChange('email')}
                        />
                        <DeepcellMultiInput
                            value={
                                sessionSearchFilter.sampleID ? sessionSearchFilter.sampleID.split(/\s+/) : []
                            }
                            updateValueState={(newVal) => updateField('sampleID', newVal)}
                            label="Sample ID"
                            inputProps={{
                                placeholder: 'Enter Sample Id',
                            }}
                        />
                        <DeepcellTextField
                            fullWidth
                            label="Sample Type"
                            value={sessionSearchFilter.sampleType}
                            placeholder="Enter Sample Type"
                            onChange={handleChange('sampleType')}
                        />
                        <DeepcellTextField
                            fullWidth
                            label="Classifier Name"
                            value={sessionSearchFilter.classifierName}
                            placeholder="Enter Classifier Name"
                            onChange={handleChange('classifierName')}
                        />
                        <Stack direction="row" spacing={2}>
                            <DeepcellPrimaryButton
                                outlined
                                data-testid="advancedRunSearchCancelButton"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </DeepcellPrimaryButton>
                            <DeepcellPrimaryButton contained onClick={handleSearch}>
                                Search
                            </DeepcellPrimaryButton>
                        </Stack>
                    </Stack>
                </StyledCardContent>
            </Card>
        </DeepcellPopover>
    )
}

export default AdvancedSessionSearchFilterPopover
