import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  PopperProps,
  Stack,
  styled,
  Typography,
} from '@mui/material'

export interface DeepcellPopperProps extends Partial<PopperProps> {
  headerTitle?: string
  open: boolean
  onClose?: () => void
  children?: React.ReactNode
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  '&': {
    // prevents elements like calendar from being clipped at the edge of the popover
    padding: '16px 16px 24px 16px',
    background: theme.palette.background.default,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
}))

/**
 * Deepcell version of an MUI Popper.
 *
 * Use this when the Popover doesn't work as needed (e.g. sometimes the MUI Popover layout breaks,
 * while the lower level MUI Popper has more robust layout behaviour)
 *
 * @param param0
 * @returns
 */
const DeepcellPopper = ({
  children,
  headerTitle,
  onClose,
  ...props
}: DeepcellPopperProps): JSX.Element => {
  function handleClose(): void {
    if (onClose) onClose()
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledPopper
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              padding: 32, // Ensure at least 32px of space from the edge of the window
              boundary: 'viewport',
            },
          },
          {
            name: 'flip', // enable flip behavior at boundaries
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
            },
          },
        ]}
        {...props}
      >
        <Stack direction="column" spacing={2}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            component="div"
          >
            <div />
            {headerTitle ? (
              <Typography
                sx={{ fontSize: '24px', lineHeight: '25px' }}
                id="popover-title"
                variant="h5"
                component="h2"
              >
                {headerTitle}
              </Typography>
            ) : (
              <div />
            )}
            <IconButton sx={{ float: 'right' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Stack>
      </StyledPopper>
    </ClickAwayListener>
  )
}

export default DeepcellPopper
