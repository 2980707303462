import { FormControlLabel, FormGroup, Stack, Switch, Tooltip } from '@mui/material'
import { CELL_IMAGE_SIZES, CELL_IMAGE_SPACING_OPTIONS } from 'components/cell-visualizations/shared'
import useDisplayImagesControl from 'components/cell-visualizations/useDisplayImagesControl'
import DeepcellLabel from 'components/shared/DeepcellLabel'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import DeepcellPrimaryIconButton from 'components/shared/DeepcellPrimaryIconButton'
import DeepcellSecondarySelect from 'components/shared/DeepcellSecondarySelect'
import HubspotReactForm from 'components/shared/HubspotReactForm'
import ChatIcon from 'components/shared/icons/ChatIcon'
import useFlags from 'components/shared/useFlags'
import { useState } from 'react'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

export const PlotImagesFilter = (): JSX.Element => {
  const { demoEnabled } = useFlags()
  const contactUsVisible = demoEnabled === 'yes'
  const {
    displayImages,
    onDisplayImagesChange,
    imageSize,
    spacingAdjust,
    onImageSizeChange,
    onSpacingAdjustChange,
  } = useDisplayImagesControl()

  const eventsManager = useEventsManager()

  const [showContactUs, setShowContactUs] = useState(false)

  return (
    <>
      <Stack
        id="plot-images-filter"
        direction="row"
        spacing={2}
        data-testid="plot-images-filter"
        alignItems="center"
      >
        <Tooltip title="Display Images (I)" placement="top">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={displayImages} onChange={onDisplayImagesChange} />}
              sx={{ color: 'black' }}
              label="Display Images (I)"
              labelPlacement="start"
            />
          </FormGroup>
        </Tooltip>

        <div style={{ display: 'flex', gap: '8px' }}>
          <DeepcellLabel label="Image Size" />
          <DeepcellSecondarySelect
            items={CELL_IMAGE_SIZES}
            value={imageSize}
            onChange={onImageSizeChange}
            sx={{ width: '8rem' }}
            formControlProps={{ fullWidth: false }}
          />
        </div>

        <div style={{ display: 'flex', gap: '8px' }}>
          <DeepcellLabel label="Image Spacing" />
          <DeepcellSecondarySelect
            items={CELL_IMAGE_SPACING_OPTIONS}
            value={spacingAdjust}
            onChange={onSpacingAdjustChange}
            sx={{ width: '8rem' }}
            formControlProps={{ fullWidth: false }}
          />
        </div>
        {contactUsVisible && (
          <DeepcellPrimaryIconButton
            data-testid="contact-us-button"
            onClick={() => {
              eventsManager.sendContactUsByEvent(new Date().getTime())
              return setShowContactUs(true)
            }}
            size="small"
            outlined
            sx={{
              position: 'absolute',
              bottom: '13px',
              right: '23px',
              paddingY: '10px !important',
              backgroundColor: (t) => t.palette.success.main,
              '&:hover': {
                boxShadow: '0px 0px 10px #5F55D1',
                backgroundColor: (t) => t.palette.success.main,
              },
            }}
            icon={<ChatIcon />}
          />
        )}
      </Stack>

      <DeepcellPopover
        open={showContactUs}
        sx={{
          '& .MuiPopover-paper': {
            width: '400px',
            minHeight: '450px',
            inset: 'unset !important',
            bottom: '13px !important',
            right: '16px !important',
          },
        }}
        onClose={() => setShowContactUs(false)}
      >
        <HubspotReactForm
          portalId="22481347"
          formId="078978ad-3b6c-4eec-ba76-73ec4bb2849e"
          onFormSubmitted={() => {
            eventsManager.sendContactedUsSubmittedEvent('footer')
          }}
        />
      </DeepcellPopover>
    </>
  )
}

export default PlotImagesFilter
