import HubspotForm from 'react-hubspot-form'

interface HubspotReactFormProps {
  portalId: string
  formId: string
  onSubmit?: (form: HTMLFormElement) => void
  onFormSubmitted?: (form: HTMLFormElement) => void
}

const HubspotReactForm = ({
  portalId,
  formId,
  onSubmit,
  onFormSubmitted,
}: HubspotReactFormProps): JSX.Element => {
  return (
    <HubspotForm
      portalId={portalId}
      formId={formId}
      onSubmit={onSubmit}
      onFormSubmitted={onFormSubmitted}
    />
  )
}

export default HubspotReactForm
