import { Alert, Box, LinearProgress, Snackbar, SnackbarCloseReason, styled } from '@mui/material'
import Parser from 'html-react-parser'
import { NotificationState } from 'redux/slices'

const NotificationAlert = styled(Alert)({
  width: '100%',
  '& a': { color: 'white', fontWeight: 'bold' },
})

const ProgressClass = styled(LinearProgress)({
  position: 'relative',
  top: '-10px',
  margin: '0 20px',
})

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-anchorOriginBottomCenter': {
    bottom: '100px',
  },
})

export interface NotificationBaseProps {
  notification: NotificationState
  handleClose(_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason): void
}

export const ToasterComponent = (props: NotificationBaseProps): JSX.Element => {
  const {
    handleClose,
    notification: { message, open, origin, showIcon, showProgress, timeout, type },
  } = props
  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={timeout}
      onClose={handleClose}
      anchorOrigin={{
        vertical: origin?.vertical ?? 'top',
        horizontal: origin?.horizontal ?? 'center',
      }}
    >
      <Box>
        <NotificationAlert
          variant="filled"
          onClose={handleClose}
          severity={type}
          icon={showIcon ? undefined : false}
          sx={{ width: '100%' }}
        >
          {Parser(message ?? '')}
        </NotificationAlert>
        {showProgress && <ProgressClass />}
      </Box>
    </StyledSnackbar>
  )
}

export default ToasterComponent
