import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material'

interface PopupIFrameProps {
  title: string
  handleCancel: () => void
  open: boolean
  videoUrl: string
}

const PopupIFrame = ({ title, handleCancel, open, videoUrl }: PopupIFrameProps): JSX.Element => {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: 'none !important',
        },
      }}
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box>
          <iframe
            width="600"
            height="500"
            src={videoUrl}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PopupIFrame
