import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { UseQueryResult } from 'react-query/types/react/types'
import { QueryClient, useQuery } from 'react-query'
import ms from 'ms'
import { CellImageFilters, getCellImage, GetCellImageParams } from './api'

export type CellImageQueryKey = ['getCellImage', GetCellImageParams]

export interface CellImageInfo {
  cellId: CellId | undefined
  frame: number
  width: number
  filters: CellImageFilters
}

/** Prefetch a cell image into react-query cache */
export async function prefetchCellImage(
  queryClient: QueryClient,
  cellId: CellId,
  frame: number,
  width: number,
  filters: CellImageFilters
): Promise<void> {
  const params: GetCellImageParams = {
    number: cellId.getNumber(),
    time: cellId.getTime(),
    instrumentId: cellId.getInstrumentId(),
    frame,
    width,
    filters,
  }

  await queryClient.prefetchQuery(['getCellImage', params], getCellImage, {
    cacheTime: ms('1 hour'),
    staleTime: ms('1 hour'),
  })
}

export const getCellImageQueryKey = ({
  cellId,
  filters,
  frame,
  width,
}: CellImageInfo): CellImageQueryKey => {
  const params: GetCellImageParams = {
    number: cellId?.getNumber(),
    time: cellId?.getTime(),
    instrumentId: cellId?.getInstrumentId(),
    frame,
    width,
    filters,
  }

  return ['getCellImage', params]
}

/**
 * Hook that returns a data url for an image.
 */
export default function useCellImage(
  cellImageInfo: CellImageInfo
): UseQueryResult<string, unknown> {
  const { cellId } = cellImageInfo
  const queryKey = getCellImageQueryKey(cellImageInfo)

  return useQuery(queryKey, getCellImage, {
    enabled:
      cellId !== null &&
      cellId !== undefined &&
      cellId.getNumber() !== null &&
      cellId.getNumber() !== undefined,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: ms('1 hour'),
    staleTime: ms('1 hour'),
  })
}
