import { CellImageId } from 'utils/api'
import TTLCache from '@isaacs/ttlcache'
import ms from 'ms'

type DataURLCacheOptions = {
  maxSize: number // Maximum size of the cache
  ttl: number // Time to live in milliseconds for cached DataURLs
  fetchingTtl: number // Time to live in milliseconds for CellIds flagged as 'fetching'
}

/** A DataURL Cache that caches DataURLs based on CellId keys */
class DataURLCache {
  // Cache that uses serialized CellId values for keys and either a DataURL as value or boolean true if
  // the data for the CellId is actively being fetched
  cache: TTLCache<string, string | true>

  // Number of seconds to keep an actively fetching CellId in the cache
  // This is normally used only to deduplicate requests over a short span of time
  // (e.g. if a React component renders multiple times in quick succession)
  // So this TTL is normally set much lower than the TTL for DataURLs in the cache
  fetchingTtl: number

  /**
   * Create a new DataURL Cache of the requestsed size and TTL that caches data by CellImageId
   *
   * @param options Options to set on the cache
   */
  constructor(options?: Partial<DataURLCacheOptions>) {
    const max = options?.maxSize || 20000
    const ttl = options?.ttl || ms('15 minutes')
    this.cache = new TTLCache({ max, ttl })
    this.fetchingTtl = options?.fetchingTtl || ms('5 seconds')
  }

  private getKey(cellImageId: CellImageId) {
    return `${cellImageId?.cellId.getTime()}_${cellImageId?.cellId.getNumber()}_${cellImageId?.cellId.getInstrumentId()}_${
      cellImageId?.frame
    }`
  }

  private getValue(cellImageId: CellImageId) {
    const key = this.getKey(cellImageId)
    return this.cache.get(key)
  }

  /**
   * Returns whether there is a dataURL for a cellId cached or not
   * @param cellId The CellImageId to look up
   * @param includeFetching Set to true to include CellIds that are still fetching
   *  This is used to avoid fetching multiple times in a short span of time
   */
  isCached(cellImageId: CellImageId, includeFetching = true): boolean {
    const value = this.getValue(cellImageId)
    if (value === true) return includeFetching
    return value !== undefined
  }

  getDataURL(cellImageId: CellImageId): string | undefined {
    const value = this.getValue(cellImageId)
    if (value !== true) return value
    return undefined
  }

  markFetching(cellImageId: CellImageId): void {
    const key = this.getKey(cellImageId)
    this.cache.set(key, true, {
      ttl: this.fetchingTtl,
    })
  }

  cacheDataURL(cellImageId: CellImageId, dataURL: string): void {
    const key = this.getKey(cellImageId)
    this.cache.set(key, dataURL)
  }
}

export default DataURLCache
