import { createSvgIcon } from '@mui/material'

const BoxSelectIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="-3 -3 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14.125V15C0 15.5523 0.447715 16 1 16H1.875V15H1V14.125H0ZM0 12.375H1V10.625H0V12.375ZM0 8.875H1V7.125H0V8.875ZM0 5.375H1V3.625H0V5.375ZM0 1.875H1V1H1.875V0H1C0.447715 0 0 0.447715 0 1V1.875ZM3.625 0V1H5.375V0H3.625ZM7.125 0V1H8.875V0H7.125ZM10.625 0V1H12.375V0H10.625ZM14.125 0V1H15V1.875H16V1C16 0.447715 15.5523 0 15 0H14.125ZM16 3.625H15V5.375H16V3.625ZM16 7.125H15V8.875H16V7.125ZM16 10.625H15V12.375H16V10.625ZM16 14.125H15V15H14.125V16H15C15.5523 16 16 15.5523 16 15V14.125ZM12.375 16V15H10.625V16H12.375ZM8.875 16V15H7.125V16H8.875ZM5.375 16V15H3.625V16H5.375Z"
      fill="#172623"
    />
  </svg>,
  'Pan'
)

export default BoxSelectIcon
