import { Stack } from '@mui/material'
import { DeepcellDialog, DeepcellTextField } from 'components/shared'
import { ChangeEvent, useEffect, useState } from 'react'

type SessionMetaData = {
  name?: string
  projectCode?: string
  isCopy?: boolean
}

export interface SaveSessionDialogProps {
  open?: boolean
  onSaveSession?(data: SessionMetaData): void
  onClose?(): void
  defaultName?: SessionMetaData['name']
  defaultProjectCode?: SessionMetaData['projectCode']
  title?: string
  isCopy?: boolean
}

export const SaveSessionDialog = (props: SaveSessionDialogProps): JSX.Element => {
  const { open, onSaveSession, onClose, defaultName, defaultProjectCode, title, isCopy } = props

  const [sessionMetaData, setSessionMetaData] = useState<SessionMetaData>({
    name: defaultName,
    projectCode: defaultProjectCode,
  })
  const { name, projectCode } = sessionMetaData

  // We need these useEffects here because the component is rendered before the default(Name|ProjectCode) is populated
  useEffect(() => {
    if (defaultName) setSessionMetaData((x) => ({ ...x, name: defaultName }))
  }, [defaultName])

  useEffect(() => {
    if (defaultProjectCode) setSessionMetaData((x) => ({ ...x, projectCode: defaultProjectCode }))
  }, [defaultProjectCode])

  const resetAndClose = () => {
    setSessionMetaData({})
    if (onClose) onClose()
  }

  const onConfirm = async () => {
    if (onSaveSession) onSaveSession({ ...sessionMetaData, isCopy })
    resetAndClose()
  }

  const handleChange =
    (key: keyof SessionMetaData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = e.target.value

      setSessionMetaData((current) => ({ ...current, [key]: val }))
    }

  return (
    <DeepcellDialog
      handleCancel={resetAndClose}
      handleConfirm={onConfirm}
      open={!!open}
      okLabel="Save Session"
      showCancelButton={false}
      titleLabel={title}
    >
      <Stack spacing={2} sx={{ width: 400, px: 1 }}>
        <DeepcellTextField
          label="Session Name"
          value={name ?? ''}
          onChange={handleChange('name')}
        />
        <DeepcellTextField
          label="Project Code"
          value={projectCode ?? ''}
          onChange={handleChange('projectCode')}
        />
      </Stack>
    </DeepcellDialog>
  )
}

export default SaveSessionDialog
