import {
  Box,
  ClickAwayListener,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material'
import { PlotMouseEvent } from 'plotly.js'
import { useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import PlotCellImage from '../PlotCellImage'
import { useMorphometricData } from '../tsv/utils'
import { WithCellPoints } from './convertToDeepcellEvent'

const circleDiameter = 40
const borderThickness = circleDiameter / 6

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.dark}`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.dark,
  },
}))

export const toTenth = (value: string | undefined): string => {
  return parseFloat(value ?? '0').toFixed(2)
}

export const useCustomAnnotation = (): {
  CustomAnnotation: () => JSX.Element
  setAnnotationData: (e?: WithCellPoints<PlotMouseEvent>) => void
} => {
  const { width, height } = useWindowSize()
  const [annotationData, setAnnotationData] = useState<WithCellPoints<PlotMouseEvent> | undefined>()
  const { LONG_AXIS, SHORT_AXIS, AREA, PERIMETER, CELL_ID } = useMorphometricData(
    annotationData?.points[0]
  )
  const theme = useTheme()

  // close the annotation if the window resizes
  useEffect(() => {
    setAnnotationData(undefined)
  }, [width, height])

  const CustomAnnotation = useCallback(() => {
    if (!annotationData) return <></>

    const morphoData = {
      'Long ellipse axis (µm)': toTenth(LONG_AXIS),
      'Short ellipse axis (µm)': toTenth(SHORT_AXIS),
      'Area (µm²)': toTenth(AREA),
      'Perimeter (µm)': toTenth(PERIMETER),
    }

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setAnnotationData(undefined)}
      >
        <div>
          <StyledTooltip
            open
            arrow
            PopperProps={{
              disablePortal: true,
              placement: 'auto',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            }}
            title={
              <Stack spacing={2} p={1}>
                <PlotCellImage
                  revertToDefaultDimensions={false}
                  cellId={CELL_ID ?? ''}
                  imgProps={{
                    style: {
                      objectFit: 'contain',
                      width: '-webkit-fill-available',
                      minHeight: 185,
                    },
                  }}
                  wrapperProps={{ sx: { mt: '0 !important' } }}
                />
                <Typography color={theme.palette.text.primary} gutterBottom variant="h5">
                  Cell Morphometrics
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {Object.keys(morphoData).map((morphoKey) => {
                        const title = morphoKey as keyof typeof morphoData
                        return (
                          <TableRow key={title}>
                            <TableCell
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)',
                              }}
                            >
                              {title}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)',
                              }}
                            >
                              {morphoData[title]}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            }
          >
            <Box
              sx={{
                position: 'absolute',
                top: `calc(${annotationData.event.y}px - ${circleDiameter / 2}px)`,
                left: `calc(${annotationData.event.x}px - ${circleDiameter / 2}px)`,
                display: 'inline-block',
                backgroundColor: 'transparent',
                border: `${borderThickness}px solid ${theme.palette.info.dark}`,
                borderRadius: '50%',
                content: '""',
                width: circleDiameter,
                height: circleDiameter,
              }}
            />
          </StyledTooltip>
        </div>
      </ClickAwayListener>
    )
  }, [annotationData, CELL_ID, LONG_AXIS, SHORT_AXIS, AREA, PERIMETER, theme])

  return { CustomAnnotation, setAnnotationData }
}

export default useCustomAnnotation
