import { Stack } from '@mui/material'
import React from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DifferentialFeaturesComparison } from 'redux/slices/types'
import ResultItem from './ResultItem'

const ResultAccordionTable: React.FC = (): JSX.Element => {
  const {
    cellVisualizations: { differentialFeaturesComparisons },
  } = useCellVisualizationsSlice()

  return (
    <Stack>
      {differentialFeaturesComparisons?.map((comparison: DifferentialFeaturesComparison) => {
        return <ResultItem comparison={comparison} key={`result-item-${comparison.taskId}`} />
      })}
    </Stack>
  )
}

export default ResultAccordionTable
