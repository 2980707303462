import moment, { MomentInput, Duration } from 'moment-timezone'

export const INSTRUMENT_REPORTED_TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export const CELL_TIMEFORMAT = 'x'

/**
 * Returns a formatted date time string
 *
 * @param dateTime May be a timestamp in milliseconds or a Date
 */
export function formatDateTime(dateTime: MomentInput | undefined | null): string {
  if (dateTime === undefined || dateTime === null) return ''
  return moment(dateTime).format('YYYY-MM-DD HH:mm')
}

/**
 * Returns a formatted date string
 *
 * @param dateTime May be a timestamp in milliseconds or a Date
 */
export function formatDate(dateTime: number | Date | undefined | null): string {
  if (dateTime === undefined || dateTime === null) return ''
  return moment(dateTime).format('YYYY-MM-DD')
}

/**
 * Returns a formatted duration string in HH:MM:SS
 */
export function formatDuration(duration: Duration | undefined | null): string {
  if (duration === undefined || duration === null) return ''

  function formatNum(num: number): string {
    return Math.floor(num).toString().padStart(2, '0')
  }

  const hours = formatNum(duration.hours())
  const minutes = formatNum(duration.minutes())
  const seconds = formatNum(duration.seconds())

  return `${hours}:${minutes}:${seconds}`
}

/**
 * Returns timestamp in the user's local timezone along with the timezone information
 * regardless of the input format.
 * @param time Time as string
 * @param inputFormat as string, the input format of the provided timestamp
 * const inputTimestamp = "2024-02-08T12:00:00Z"; // Example timestamp in ISO 8601 format
 * const inputFormat = "YYYY-MM-DDTHH:mm:ssZ"; // Example input format
 * const formattedTimestamp = formatTimestamp(inputTimestamp, inputFormat);
 * console.log(formattedTimestamp); // Output: February 8th 2024, 12:00:00 pm UTC
 */

export function formatTimestampToHumanReadable(
  timestamp: string | undefined | null,
  inputFormat: string
): string {
  if (timestamp === undefined || timestamp === null) return ''
  const momentTimestamp = moment(timestamp, inputFormat)
  // Get the timezone abbreviation, if set to true, the cache will be ignored and overwritten with the new value.
  const timezone = moment.tz.guess(true)
  const timezoneAbbr = moment.tz(timezone).zoneAbbr()
  const formattedTimestamp = momentTimestamp.format('MMM Do YYYY, hh:mm A')
  return `${formattedTimestamp} (${timezoneAbbr})`
}

/**
 * Rounds time to the nearest minuteInterval minutes
 *
 * @param time Time in milliseconds
 * @param minuteInterval Minute interval to round to (default = 15 minutes)
 * @param roundDown Whether to round down or round up
 */
export function roundToNearestIntervalMinutes(
  timestamp: number | undefined | null,
  minuteInterval = 15,
  roundUp = true
): string {
  if (timestamp === undefined || timestamp === null) return ''
  const momentTimestamp = moment(timestamp)
  const roundFunction = roundUp ? Math.ceil : Math.floor
  const roundedMinutes = roundFunction(momentTimestamp.minutes() / minuteInterval) * minuteInterval
  const roundedTimestamp = momentTimestamp.clone().minutes(roundedMinutes)
  return roundedTimestamp.format('YYYY-MM-DDTHH:mm:ssZ')
}
