import { createSvgIcon } from '@mui/material'

const ChatIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="-1 -3 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1267_3129" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.47998 0.94043C1.37541 0.94043 0.47998 1.83586 0.47998 2.94043V12.0604C0.47998 13.165 1.37541 14.0604 2.47998 14.0604H11.8796L13.821 16.3022C13.9207 16.4173 14.0993 16.4173 14.199 16.3022L16.1404 14.0604H17.34C18.4445 14.0604 19.34 13.165 19.34 12.0604V2.94043C19.34 1.83586 18.4445 0.94043 17.34 0.94043H2.47998Z"
      />
    </mask>
    <path
      d="M11.8796 14.0604L12.6355 13.4058L12.3364 13.0604H11.8796V14.0604ZM13.821 16.3022L14.5769 15.6476L14.5769 15.6476L13.821 16.3022ZM14.199 16.3022L13.443 15.6476L13.443 15.6476L14.199 16.3022ZM16.1404 14.0604V13.0604H15.6835L15.3845 13.4058L16.1404 14.0604ZM1.47998 2.94043C1.47998 2.38815 1.9277 1.94043 2.47998 1.94043V-0.0595703C0.823126 -0.0595703 -0.52002 1.28358 -0.52002 2.94043H1.47998ZM1.47998 12.0604V2.94043H-0.52002V12.0604H1.47998ZM2.47998 13.0604C1.9277 13.0604 1.47998 12.6127 1.47998 12.0604H-0.52002C-0.52002 13.7173 0.823129 15.0604 2.47998 15.0604V13.0604ZM11.8796 13.0604H2.47998V15.0604H11.8796V13.0604ZM14.5769 15.6476L12.6355 13.4058L11.1236 14.7151L13.0651 16.9569L14.5769 15.6476ZM13.443 15.6476C13.7421 15.3022 14.2778 15.3022 14.5769 15.6476L13.0651 16.9569C13.5635 17.5324 14.4564 17.5324 14.9549 16.9569L13.443 15.6476ZM15.3845 13.4058L13.443 15.6476L14.9549 16.9569L16.8963 14.7151L15.3845 13.4058ZM17.34 13.0604H16.1404V15.0604H17.34V13.0604ZM18.34 12.0604C18.34 12.6127 17.8923 13.0604 17.34 13.0604V15.0604C18.9968 15.0604 20.34 13.7173 20.34 12.0604H18.34ZM18.34 2.94043V12.0604H20.34V2.94043H18.34ZM17.34 1.94043C17.8923 1.94043 18.34 2.38815 18.34 2.94043H20.34C20.34 1.28357 18.9968 -0.0595703 17.34 -0.0595703V1.94043ZM2.47998 1.94043H17.34V-0.0595703H2.47998V1.94043Z"
      fill="#5F55D1"
      mask="url(#path-1-inside-1_1267_3129)"
    />
  </svg>,
  'Chat'
)

export default ChatIcon
