import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { Run } from 'utils/api'

const runsSliceInitialState: Run[] = []

export const runsSlice = createSlice({
  name: 'runs',
  initialState: runsSliceInitialState,
  reducers: {
    set: (_state, action: PayloadAction<Run[]>) => action.payload,
    appendNew: (state, action: PayloadAction<Run[]>) => {
      // only add runs that don't already exist
      const newRuns = action.payload.filter(
        (incomingRun) =>
          !state.map((existingRun) => existingRun.run_id).includes(incomingRun.run_id)
      )
      return [...state, ...newRuns]
    },
    clear: () => runsSliceInitialState,
  },
})

export const RunsActions = runsSlice.actions
export const RunsReducer = runsSlice.reducer

export const allRuns = (state: RootState): Run[] => state.runs
