import { createSvgIcon } from '@mui/material'

const CameraIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="-2 -2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.4037 3.30769H2.6C1.71631 3.30769 1 4.08255 1 5.03846V14.2692C1 15.2251 1.71631 16 2.6 16H15.4C16.2837 16 17 15.2251 17 14.2692V5.03846C17 4.08255 16.2837 3.30769 15.4 3.30769H13.5963L12.9718 1.95657C12.7008 1.37038 12.1467 1 11.5408 1H6.45923C5.85339 1 5.29925 1.37035 5.02828 1.95657L4.4037 3.30769ZM12.6954 10.2308C12.4362 12.1872 10.8794 13.6923 8.99982 13.6923C7.12023 13.6923 5.56363 12.1872 5.30427 10.2308H2.06648V14.2692C2.06648 14.5877 2.30541 14.8462 2.59982 14.8462H15.3998C15.6942 14.8462 15.9332 14.5877 15.9332 14.2692V10.2308H12.6954ZM8.99982 6.76923C10.4718 6.76923 11.6665 8.06151 11.6665 9.65385C11.6665 11.2462 10.4718 12.5385 8.99982 12.5385C7.52779 12.5385 6.33315 11.2462 6.33315 9.65385C6.33315 8.06151 7.52779 6.76923 8.99982 6.76923ZM15.3998 4.46154H2.59982C2.30541 4.46154 2.06648 4.71999 2.06648 5.03846V9.07692H5.30427C5.56344 7.12053 7.12023 5.61539 8.99982 5.61539C10.8794 5.61539 12.436 7.12053 12.6954 9.07692H15.9332V5.03846C15.9332 4.71999 15.6942 4.46154 15.3998 4.46154ZM12.4035 3.30769H5.59605L5.98213 2.47284C6.07224 2.27735 6.25677 2.15385 6.45893 2.15385H11.5405C11.7427 2.15385 11.9272 2.27735 12.0173 2.47284L12.4035 3.30769Z"
      fill="#172623"
      stroke="white"
      strokeWidth="0.2"
    />
  </svg>,
  'Pan'
)

export default CameraIcon
