import { Card, CardActions, CardContent, Popper, Stack, SxProps } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import { useMount, useWindowSize } from 'react-use'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { StepComponentProps } from './Walkthrough'

const componentWidth = 550
type Position = 'center' | 'left' | 'right'
interface GetWalkthroughTooltipParams {
  content: React.ReactNode
  header?: React.ReactNode
  primaryButtonText?: string
  skipButtonText?: string
  showSkipButton?: boolean
  position?: Position
}

export const getStepComponent =
  (componentProps?: GetWalkthroughTooltipParams) =>
  (stepParams: StepComponentProps): JSX.Element => {
    const { setShowColorBy } = useCellVisualizationsSlice()
    const { width: windowWidth } = useWindowSize()

    const {
      header,
      primaryButtonText,
      skipButtonText,
      content,
      position = 'center',
      showSkipButton = true,
    } = componentProps ?? {}
    const { onNext, onSkip } = stepParams

    const posMap: {
      [k in Position]: React.CSSProperties
    } = {
      left: { transform: `-${windowWidth / 2 - 30}px` },
      right: { transform: `${windowWidth / 2 - componentWidth - 30}px` },
      center: { transform: '-50%' },
    }

    const dialogSx: SxProps = {
      top: '50% !important',
      left: '50% !important',
      transform: `translate(${posMap[position].transform}, -50%)`,
      zIndex: 100,
    }

    useMount(() => {
      if (position === 'left') {
        setShowColorBy(false)
      }
    })

    return (
      <Popper open sx={dialogSx}>
        <Card sx={{ p: 2, pt: 0, maxWidth: componentWidth }}>
          <CardContent>
            {header ? <h3 style={{ textAlign: 'center' }}>{header}</h3> : null}
            {content}
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Stack spacing={2}>
              <DeepcellPrimaryButton contained onClick={() => onNext()}>
                {primaryButtonText ?? 'Next'}
              </DeepcellPrimaryButton>
              {showSkipButton ? (
                <DeepcellPrimaryButton text onClick={onSkip} sx={{ textTransform: 'none' }}>
                  <u>
                    <i>{skipButtonText ?? 'Close Tutorial'}</i>
                  </u>
                </DeepcellPrimaryButton>
              ) : null}
            </Stack>
          </CardActions>
        </Card>
      </Popper>
    )
  }

export default getStepComponent
