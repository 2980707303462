import { DialogContentText, Typography } from '@mui/material'
import { NotificationState } from 'redux/slices'
import _ from 'lodash'
import DeepcellDialog, { DeepcellDialogProps } from './DeepcellDialog'

interface DialogComponentProps {
  notification: NotificationState
  handleClose: DeepcellDialogProps['handleCancel']
}
export const DialogComponent = (props: DialogComponentProps): JSX.Element => {
  const { notification, handleClose } = props
  const { message, type } = notification

  return (
    <DeepcellDialog
      open={!!notification.open}
      handleConfirm={handleClose}
      handleCancel={handleClose}
      okLabel="Ok"
      showCancelButton={false}
      titleLabel={_.capitalize(type) || 'Error'}
    >
      <DialogContentText sx={{ minWidth: 300 }}>
        <Typography mb={2}>{message}</Typography>
      </DialogContentText>
    </DeepcellDialog>
  )
}

export default DialogComponent
