import { Stack } from '@mui/material'
import { usePlotData } from 'components/cell-visualizations/plot/usePlotData'
import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { DeepcellPrimaryButton } from 'components/shared'
import DeepcellAutocomplete from 'components/shared/DeepcellAutocomplete'
import useDeepcellError from 'components/shared/useDeepcellError'
import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { ALL_OTHER_CELLS_ID, PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DifferentialFeaturesTaskInput, startDifferentialFeaturesTask, TaskStatus } from 'utils/api'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import { convertMorphotypeIdsToCellIds } from './morphotypeListUtils'

// @internal
// Represents a dropdown option for each dropdown in the differential features comparison form
export type ComparisonOption = {
  morphotypeId: number
  name: string
}

/**
 * @internal
 * Returns available selection options for a dropdown, given the state of the other dropdown
 *
 * @param morphotypeIds The list of morphotype IDs selected in this dropdown (may be empty)
 * @param otherMorphotypeIds The list of morphotype IDs selected in the other dropdown (may be empty)
 * @param mergedPinnedCells The list of merged pinned cells to use to look up morphotypes ids and names
 * */
export function getAvailableOptions(
  morphotypeIds: number[],
  otherMorphotypeIds: number[],
  pinnedCells?: PinnedMorphotype[]
): ComparisonOption[] {
  if (pinnedCells === undefined) {
    return []
  }

  const options: ComparisonOption[] = []

  /**
   * Add the "All Other Cells" option first in these cases:
   * - We haven't selected All Other Cells in the other dropdown, and we have selected some morphotypes
   * - Plus we haven't selected any morphotypes in this dropdown, or we've selected "All Other Cells" already
   */
  if (
    !otherMorphotypeIds.includes(ALL_OTHER_CELLS_ID) &&
    otherMorphotypeIds.length > 0 &&
    (morphotypeIds.length === 0 || morphotypeIds.includes(ALL_OTHER_CELLS_ID))
  ) {
    options.push({
      morphotypeId: ALL_OTHER_CELLS_ID,
      name: 'All Other Cells',
    })

    // If user has already chosen "All Other Cells", don't let them choose any other morphotypes
    if (morphotypeIds.includes(ALL_OTHER_CELLS_ID)) {
      return options
    }
  }

  const addedNames = new Set<string>()

  /** Otherwise, add in any morphotypes that aren't in otherMorphotypeIds */
  pinnedCells.forEach((morphotype) => {
    if (!otherMorphotypeIds.includes(morphotype.id)) {
      // Add the option, if it's not already added
      if (!addedNames.has(morphotype.name)) {
        options.push({
          morphotypeId: morphotype.id,
          name: morphotype.name,
        })
        addedNames.add(morphotype.name)
      }
    }
  })

  return options
}

const CreateComparisonForm = (): JSX.Element => {
  // Stores the currently selected morphotypes for comparison
  // Or may store [ALL_OTHER_CELLS_ID] if "All Other Cells" is selected
  const [chosenOptions1, setChosenOptions1] = useState<ComparisonOption[]>([])
  const [chosenOptions2, setChosenOptions2] = useState<ComparisonOption[]>([])
  
  const eventsManager=useEventManager()

  const morphotypeIds1 = chosenOptions1.map((option) => option.morphotypeId)
  const morphotypeIds2 = chosenOptions2.map((option) => option.morphotypeId)

  const isValidComparison = morphotypeIds1.length > 0 && morphotypeIds2.length > 0

  const { cellInfoUnfiltered } = usePlotData()

  const { visiblePinnedCells: pinnedCells, addDifferentialFeaturesComparison } =
    useCellVisualizationsSlice()

  const availableOptions1 = useMemo(
    () => getAvailableOptions(morphotypeIds1, morphotypeIds2, pinnedCells),
    [morphotypeIds1, morphotypeIds2, pinnedCells]
  )
  const availableOptions2 = useMemo(
    () => getAvailableOptions(morphotypeIds2, morphotypeIds1, pinnedCells),
    [morphotypeIds1, morphotypeIds2, pinnedCells]
  )

  const queryClient = useQueryClient()
  const { sessionId, versionId } = useCellVisualizationUrlParams()

  const { showError } = useDeepcellError()

  const startComparison = async () => {
    const payload: DifferentialFeaturesTaskInput = {
      sessionId,
      versionId,
      cellIdList1: convertMorphotypeIdsToCellIds(
        morphotypeIds1,
        morphotypeIds2,
        cellInfoUnfiltered,
        pinnedCells
      ),
      cellIdList2: convertMorphotypeIdsToCellIds(
        morphotypeIds2,
        morphotypeIds1,
        cellInfoUnfiltered,
        pinnedCells
      ),
    }

    try {
      // Start the task
      const result = await queryClient.fetchQuery(
        ['startDifferentialFeaturesTask', payload],
        startDifferentialFeaturesTask
      )
      const { task_id: taskId } = result.data

      eventsManager.sendMorphotypesEvent(
        morphotypeIds1.includes(ALL_OTHER_CELLS_ID) ? 'all other cells' : morphotypeIds1,
        morphotypeIds2.includes(ALL_OTHER_CELLS_ID) ? 'all other cells' : morphotypeIds2
      );

      // Add the pending comparison to the results panel
      // And the resuts panel will poll until it has a result
      //
      // This also more robustly handles any cases where the user saves the session while a background task is still running
      // Since the responsibility to load the results will be in the ResultSummary component items
      addDifferentialFeaturesComparison({
        morphotypeIds1,
        morphotypeIds2,
        taskId,
        status: TaskStatus.PENDING,
      })


      // Clear out the form
      setChosenOptions1([])
      setChosenOptions2([])
    } catch (error) {
      console.error('Error starting differential features task', error)

      // @TODO Add in better error messages later -- not sure there are any user-recoverable errors
      // at the moment, so just use the default error message that says something went wrong
      // contact support.
      showError('DEFAULT')
    }
  }

  const isOptionEqualToValue = (option: ComparisonOption, value: ComparisonOption) => {
    return option.morphotypeId === value.morphotypeId
  }

  return (
    <Stack spacing={2}>
      <Stack spacing="12px">
        <DeepcellAutocomplete
          multiple
          label="Compare"
          id="cell-group-ids-1"
          options={availableOptions1}
          getOptionLabel={(option) => (option as ComparisonOption).name}
          filterSelectedOptions
          value={chosenOptions1}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_event, values) => {
            setChosenOptions1(values as ComparisonOption[])
          }}
        />
        <DeepcellAutocomplete
          multiple
          label="With"
          id="cell-group-ids-2"
          options={availableOptions2}
          getOptionLabel={(option) => (option as ComparisonOption).name}
          filterSelectedOptions
          value={chosenOptions2}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_event, values) => {
            setChosenOptions2(values as ComparisonOption[])
          }}
        />
      </Stack>
      <DeepcellPrimaryButton
        contained
        type="button"
        disabled={!isValidComparison}
        onClick={startComparison}
      >
        Compare Morphotypes
      </DeepcellPrimaryButton>
    </Stack>
  )
}

export default CreateComparisonForm
