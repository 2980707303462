import * as DEMO from 'constants/demo'
import * as ENVIRONMENTS from 'constants/environments'
import { useAuth0 } from '@auth0/auth0-react'
import {
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js'
import { getDemoToken } from 'utils/api'
import { USER_AUTHORIZATION_KEY } from './useAuthTokens'

async function getAccessTokenFromM2M() {
  const res = await getDemoToken()
  if (res.data.token) {
    return res.data.token
  }
  throw new Error('Demo Token API response was OK, but demo token not found on response.')
}

async function mockIdTokenClaims(): Promise<IdToken> {
  // We're faking an idToken here, but we only ever use the permissions part of the token when we call getIdTokenClaims
  return { [USER_AUTHORIZATION_KEY]: DEMO.ID_TOKEN_PERMISSIONS } as unknown as IdToken
}

type CustomUseAuth0ReturnType = Omit<
  ReturnType<typeof useAuth0>,
  | 'getAccessTokenSilently'
  | 'getAccessTokenWithPopup'
  | 'loginWithPopup'
  | 'buildAuthorizeUrl'
  | 'buildLogoutUrl'
  | 'handleRedirectCallback'
> & {
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
}

const useDemoAuth: CustomUseAuth0ReturnType = {
  isLoading: false,
  user: { org_id: DEMO.ORG_ID, email: DEMO.EMAIL },
  loginWithRedirect: async (_options?: RedirectLoginOptions) => {},
  error: undefined,
  isAuthenticated: true,
  logout: async (_options?: LogoutOptions) => {},
  getAccessTokenSilently: getAccessTokenFromM2M,
  getIdTokenClaims: mockIdTokenClaims,
}

export function getCellImagesAPIRoute(environment: string | undefined): string {
  if (environment === ENVIRONMENTS.EXTERNAL_DEMO) {
    return DEMO.DEMO_CELL_IMAGE_API_ROUTE
  }
  return DEMO.PROD_CELL_IMAGE_API_ROUTE
}

export function getBatchURLAPIRoute(environment: string | undefined): string {
  if (environment === ENVIRONMENTS.EXTERNAL_DEMO) {
    return DEMO.DEMO_BATCH_URL_API_ROUTE
  }
  return DEMO.PROD_BATCH_URL_API_ROUTE
}

export const useAuthWrapper = (fn: typeof useAuth0): CustomUseAuth0ReturnType => {
  // eslint-disable-line
  if (process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO) {
    return useDemoAuth
  }
  return fn()
}
