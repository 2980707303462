import { TextFieldProps } from '@mui/material'
import { DeepcellTextField } from 'components/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import OkCancelDialogBody from 'components/shared/OkCancelDialogBody'
import React, { useState } from 'react'

interface EditTextPopoverProps {
  anchorEl: HTMLElement | null
  textBeingEdited?: string
  onClose?: () => void
  onSave?: (newText?: string) => void
  textFieldProps?: TextFieldProps
  closeOnSave?: boolean
}
export const EditTextPopover = ({
  anchorEl,
  textBeingEdited,
  onClose,
  onSave,
  textFieldProps,
  closeOnSave = true,
}: EditTextPopoverProps): JSX.Element => {
  const [newText, setNewText] = useState(textBeingEdited ?? '')

  const handleClose = () => {
    setNewText('')
    if (onClose) onClose()
  }

  const handleTextChangeSave = () => {
    if (onSave) onSave(newText)
    if (closeOnSave) handleClose()
  }

  return (
    <DeepcellPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <OkCancelDialogBody
        titleLabel={`Edit ${textBeingEdited ?? ''}`}
        pending={false}
        handleButtonClick={(canceled) => (canceled ? handleClose() : handleTextChangeSave())}
      >
        <DeepcellTextField
          value={newText}
          onChange={(e) => setNewText(e.target.value)}
          autoFocus
          onFocus={(e) => e.currentTarget.select()}
          {...textFieldProps}
        />
      </OkCancelDialogBody>
    </DeepcellPopover>
  )
}

export default EditTextPopover
