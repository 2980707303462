import { FallbackProps } from 'react-error-boundary'
import { ErrorInfo, useEffect } from 'react'
import { errorBundle } from 'utils/constants'
import _ from 'lodash'
import { Box, Stack, Typography, styled } from '@mui/material'
import useErrorSlice from 'redux/slices/hooks/useErrorSlice'
import DeepcellPrimaryButton from './DeepcellPrimaryButton'
import { ReasonCode, ThrowError } from './useDeepcellError'

const ErrorLabel = styled(Typography)({
  fontSize: '28px',
  display: 'inline-block',
})

export const onErrorLog = (error: Error, info: ErrorInfo): void => {
  console.error(error)
  console.error(info)
}

interface FallbackComponentProps extends FallbackProps {
  error: ThrowError
}

export const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: FallbackComponentProps): JSX.Element => {
  const errorType = _.get(errorBundle, error.reason as ReasonCode, errorBundle.DEFAULT)
  const { message, severity } = _.defaults(error.options || {}, errorType)

  const { triggerError, clearError } = useErrorSlice()
  useEffect(() => {
    if (error) {
      triggerError()
    }
  }, [error, triggerError])

  const resetBoundary = () => {
    resetErrorBoundary()
    clearError()
  }

  return (
    <Stack sx={{ height: '100vh', width: '100wh' }} alignItems="center" justifyContent="center">
      <Box
        data-testid="error-boundary"
        sx={{ display: 'inline-block', textAlign: 'center' }}
        width="100%"
      >
        <ErrorLabel sx={{ fontWeight: 600 }}>{_.capitalize(severity)}:&nbsp;</ErrorLabel>
        <ErrorLabel>{message}</ErrorLabel>
      </Box>
      <DeepcellPrimaryButton outlined onClick={resetBoundary}>
        Retry
      </DeepcellPrimaryButton>
    </Stack>
  )
}

export default FallbackComponent
