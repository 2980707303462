import useDeepcellError, { ReasonCode } from 'components/shared/useDeepcellError'
import { notificationComponent } from 'redux/slices/NotificationSlice'
import CellVisualizationDirtyPrompt from './CellVisualizationDirtyPrompt'
import { CellVisualizationScene } from './CellVisualizationScene'
import { PlotDataContextProvider } from './plot/usePlotData'
import { EXErrorWithMessage } from './useSessionApi'
import useLoadCellInfoEffect from './arrow/useLoadCellInfoEffect'

export const CellVisualizationsPage = (): JSX.Element => {
  const { showError: showDeepcellError } = useDeepcellError()
  // TODO: Need to handle the errors
  const handleError = (error: unknown) => {
    const deepcellError = error as EXErrorWithMessage
    showDeepcellError(deepcellError?.data?.reason as ReasonCode, {
      message: deepcellError?.data?.error,
      component: notificationComponent.Toaster,
      severity: 'error',
    })
  }
  useLoadCellInfoEffect(handleError)

  return (
    <PlotDataContextProvider>
      <CellVisualizationScene />
      <CellVisualizationDirtyPrompt />
    </PlotDataContextProvider>
  )
}

export default CellVisualizationsPage
