export const ID_TOKEN_PERMISSIONS = {
  permissions: ['cells:graph_visualization:read'],
}

export const ORG_ID = 'external_demo'
export const EMAIL = 'demo_user'
export const DEMO_CELL_IMAGE_API_ROUTE = 'v1/demo/images'
export const PROD_CELL_IMAGE_API_ROUTE = 'v1/cells/images'
export const DEMO_BATCH_URL_API_ROUTE = 'v1/demo/cells:get_batch_image_urls'
export const PROD_BATCH_URL_API_ROUTE = 'v1/cells/cells:get_batch_image_urls'
