import { SnackbarCloseReason } from '@mui/material'
import React from 'react'
import { createPortal } from 'react-dom'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { notificationComponent } from 'redux/slices'
import { ToasterComponent } from './ToasterComponent'
import { CancelReason } from './DeepcellDialog'
import { DialogComponent } from './DialogComponent'

export const DeepcellNotification = (): JSX.Element => {
  const { notification, clearNotification } = useNotificationSlice()
  const { component } = notification
  const isToaster = component === notificationComponent.Toaster

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason | CancelReason
  ) => {
    const clickedAway = reason === 'clickaway' || reason === 'backdropClick'
    if (!notification.closeOnClickaway && clickedAway) {
      return
    }
    clearNotification()
  }

  const attachToDOMNode = () => {
    if (document?.fullscreenElement) return document.fullscreenElement
    return document.body
  }

  return createPortal(
    isToaster ? (
      <ToasterComponent handleClose={handleClose} notification={notification} />
    ) : (
      <DialogComponent handleClose={(r) => handleClose(undefined, r)} notification={notification} />
    ),
    attachToDOMNode()
  )
}

export default DeepcellNotification
