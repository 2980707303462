import { ICellRendererParams } from 'ag-grid-community'
import PrimaryButton from 'components/runs/PrimaryButton'

const OpenSessionComponent = (props: Partial<ICellRendererParams>): JSX.Element => {
  const { node, colDef } = props || {}

  const { handleOpenSession } = colDef?.cellRendererParams || {}
  
  return (
    <>
      {node?.data && (
        <PrimaryButton
          onClick={() => {
            node?.setSelected(true);
            handleOpenSession(node?.data.sessionId);
          }}
          style={{ marginLeft: 30 }}
        >
          Open Session
        </PrimaryButton>
      )}
    </>
  );
}

export default OpenSessionComponent
