/* eslint-disable no-restricted-syntax */
import { Table, tableFromIPC } from 'apache-arrow'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { getArrowFile, SessionPostData } from 'utils/api'

/**
 * TODO: We shouldn't need this function and it adds about 1s of wait time for every 50k cells
 * We could get rid of it if the arrow file data values were guaranteed to be all be simple types (and not bigints)
 */
function convertObjectValuesToStrings(obj: Record<string, unknown>): Record<string, string> {
  const result: Record<string, string> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = `${obj[key]}`
    }
  }

  return result
}

const getTableFromDataUrl = async (dataUrl: string): Promise<Table | null> => {
  const arrowResults = await getArrowFile(dataUrl)
  const { status: arrowFileStatus } = arrowResults

  if (arrowFileStatus === 200) {
    const t = await tableFromIPC(arrowResults)

    return t
  }
  return null
}

type GetSessionDataParams = {
  sessionId?: number
  sessionData?: SessionPostData
  sessionStatus?:number
}

export type SessionData = { cellsData?: CellInfo[]; sessionData?: SessionPostData }

export const getSessionData = async (params:GetSessionDataParams):Promise<SessionData> => {
  const { sessionId,sessionData,sessionStatus } = params

  if (!sessionId) return {}

  if (sessionStatus === 200 && sessionData?.data_url) {
    const table = await getTableFromDataUrl(sessionData.data_url)

    if (table) {
      const cellsData: CellInfo[] = []

      const tableArray = table.toArray()
      for (const element of tableArray) {
        cellsData.push(convertObjectValuesToStrings(element.toJSON()) as CellInfo)
      }

      return { cellsData, sessionData }
    }
  }

  return {}
}

export default getSessionData
