import { SearchDetailsQueryState } from 'components/shared/SearchDetailsView'
import { DateOperator, RunSearchFilterState, RunSearchStatus } from 'redux/slices'
import {
  createEnumParam,
  DecodedValueMap,
  QueryParamConfigMap,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { withIgnoreBlanks } from 'utils/custom-query-params'
import { ColumnStateQueryParam, ColumnStateQueryState } from 'utils/useColumnStateManager'

type QueryState = RunSearchFilterState & SearchDetailsQueryState & ColumnStateQueryState

export const useRunQueryParams = (): {
  query: DecodedValueMap<QueryParamConfigMap>
  setQuery: SetQuery<QueryParamConfigMap>
  search: QueryState
} => {
  const [query, setQuery] = useQueryParams({
    keyword: withDefault(withIgnoreBlanks(StringParam), undefined),
    runIds: withDefault(withIgnoreBlanks(StringParam), undefined),
    runDateStart: withDefault(withIgnoreBlanks(StringParam), undefined),
    runDateEnd: withDefault(withIgnoreBlanks(StringParam), undefined),
    runDateOperator: withDefault(
      withIgnoreBlanks(createEnumParam(Object.keys(DateOperator))),
      undefined
    ),
    sampleID: withDefault(withIgnoreBlanks(StringParam), undefined),
    operator: withDefault(withIgnoreBlanks(StringParam), undefined),
    status: withDefault(withIgnoreBlanks(createEnumParam(Object.keys(RunSearchStatus))), undefined),
    ...ColumnStateQueryParam,
  })
  const search = query as QueryState

  return { query, setQuery, search }
}

export default useRunQueryParams
