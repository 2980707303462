import { User } from '@auth0/auth0-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const userInitialState: User = {}

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    set: (_state, action: PayloadAction<User>) => action.payload,
  },
})

export const UserActions = userSlice.actions
export const UserReducer = userSlice.reducer
