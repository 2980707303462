import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAuthTokens from 'utils/useAuthTokens'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import * as ROUTES from '../constants/routes'

function LoginPage(): React.ReactElement {
  const { isAuthenticated, error, loginWithRedirect } = useAuthWrapper(useAuth0)
  const { resetStorage } = useAuthTokens()
  const { pathname } = useLocation()
  const history = useHistory()

  if (error && error?.message.indexOf('parameter organization is invalid') !== -1) {
    resetStorage()
    loginWithRedirect({
      appState: {
        returnTo: window.location.origin,
      },
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (pathname && pathname !== '/login') history.push(pathname)
      else history.push(ROUTES.HOME)
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      })
    }
  }, [isAuthenticated, history, loginWithRedirect, pathname])

  return <div />
}

export default LoginPage
