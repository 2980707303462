import { ALL_OTHER_CELLS_ID, DifferentialFeaturesComparison, PinnedMorphotype } from 'redux/slices'
import { FEATURES } from 'utils/constants'

/**
 * @internal
 * Get the name of the comparison for one of the selections
 *
 */

export function getComparisonNameForOneSelection(
  chosenMorphotypeIds: number[],
  pinnedCells?: PinnedMorphotype[]
): string {
  const results: string[] = []

  chosenMorphotypeIds.forEach((morphotypeId) => {
    if (morphotypeId === ALL_OTHER_CELLS_ID) {
      results.push('All Other Cells')
    } else {
      const morphotype = pinnedCells?.find((item) => item.id === morphotypeId)
      if (morphotype !== undefined) {
        results.push(morphotype.name)
      }
    }
  })

  return results.join(' & ')
}
/**
 * @internal
 * Get the name of the comparison
 * @param comparison The comparison to get the name of
 * @param pinnedCells The current pinned morphotypes
 */

export function getComparisonName(
  comparison?: DifferentialFeaturesComparison,
  pinnedCells?: PinnedMorphotype[]
): string {
  if (comparison === undefined) {
    return ''
  }

  const name1 = getComparisonNameForOneSelection(comparison.morphotypeIds1, pinnedCells)
  const name2 = getComparisonNameForOneSelection(comparison.morphotypeIds2, pinnedCells)

  return `${name1} vs. ${name2}`
}

export function getFeatureName(featureId: string): string {
  return FEATURES.find((feature) => feature.field === featureId)?.label ?? featureId
}
