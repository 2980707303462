import BarChartIcon from '@mui/icons-material/BarChart'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import { Stack } from '@mui/material'
import { FullscreenPanelType, MorphotypePanelDisplayMode } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import SelectableButton from './SelectableButton'

export const dataVizMorphotypesButtonId = 'dataVizMorphotypesButtonId'
export const dataVizCompareGroupsButtonId = 'dataVizCompareGroupsButtonId'

export const MorphotypesCompareButtons = (): JSX.Element => {
  const {
    setShowMorphotypes,
    setShowCompare,
    setFullscreenPanel,
    setMorphotypePanelDisplayMode,
    cellVisualizations: { showMorphotypes, showCompare },
  } = useCellVisualizationsSlice()

  // The logic in this component interacts with the actual panels rendered in CellSelectionDrawer
  // Keep these two in sync!

  function handleToggleMorphotypes() {
    // if we're hiding morphotypes panel, also disable the fullscreen panel
    if (showMorphotypes) setFullscreenPanel(FullscreenPanelType.NONE)

    setShowMorphotypes(!showMorphotypes)
    setMorphotypePanelDisplayMode(MorphotypePanelDisplayMode.CELL_GROUPS)

  }

  function handleToggleCompare() {
    // If we're about to show compare, collapse the full screen panel
    if (!showCompare) setFullscreenPanel(FullscreenPanelType.NONE)

    setShowCompare(!showCompare)
  }

  return (
    <Stack direction="row" spacing={2}>
      <SelectableButton
        id={dataVizMorphotypesButtonId}
        selected={showMorphotypes}
        onClick={handleToggleMorphotypes}
        startIcon={<ScatterPlotIcon />}
      >
        Morphotypes
      </SelectableButton>
      <SelectableButton
        id={dataVizCompareGroupsButtonId}
        selected={showCompare}
        onClick={handleToggleCompare}
        startIcon={<BarChartIcon />}
      >
        Compare
      </SelectableButton>
    </Stack>
  )
}
export default MorphotypesCompareButtons
