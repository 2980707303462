import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, styled, Typography } from '@mui/material'
import Popover, { PopoverProps } from '@mui/material/Popover'

export interface DeepcellPopoverProps extends PopoverProps {
  headerTitle?: string
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    // prevents elements like calendar from being clipped at the edge of the popover
    overflow: 'visible',
    padding: '16px 16px 24px 16px',
    background: theme.palette.background.default,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  '& .MuiDialogContent-root': {
    padding: `${theme.spacing(2)} 0px`,
    overflow: 'visible',
  },
  '& .MuiDialogTitle-root': {
    padding: `${theme.spacing(2)} 0px`,
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(2)} 0px`,
  },
}))

const DeepcellPopover = ({
  children,
  headerTitle,
  onClose,
  ...props
}: DeepcellPopoverProps): JSX.Element => {
  return (
    <StyledPopover onClose={onClose} {...props}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        component="div"
      >
        <div />
        {headerTitle ? (
          <Typography
            sx={{ fontSize: '24px', lineHeight: '25px' }}
            id="popover-title"
            variant="h5"
            component="h2"
          >
            {headerTitle}
          </Typography>
        ) : (
          <div />
        )}
        <IconButton
          sx={{ float: 'right' }}
          onClick={(event) => {
            if (onClose !== undefined) onClose(event, 'backdropClick')
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </StyledPopover>
  )
}

export default DeepcellPopover
