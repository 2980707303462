import { deepcellFooterId } from 'components/shared/DeepcellFooter'
import useElementById from './useElementById'

export const useFooterHeight = (): number => {
  const { rect: footer } = useElementById(deepcellFooterId)
  const footerHeight = footer?.height ?? 0

  return footerHeight
}

export default useFooterHeight
