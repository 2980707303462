import { createSvgIcon } from '@mui/material'

const LassoIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="-2 -2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.96262 17V7.65662L16 13.4962H11.5553L8.96262 17Z" fill="#172623" />
    <path
      d="M13.0369 11.1604C16 9.21378 16.3704 6.87801 15.6295 4.9314C15.0143 3.31497 13.0368 0.64909 8.59225 1.03833C4.16294 1.42623 0.0732812 4.9314 1.18448 9.21378C1.38632 9.99162 1.80051 10.6368 2.34635 11.1604M9.33302 12.7175C7.61373 13.3199 4.21149 12.9496 2.34635 11.1604M2.34635 11.1604C1.80906 12.1985 1.3721 14.2748 3.92258 14.2748M4.51794 12.7175C4.46348 13.1183 4.2841 13.7109 3.92258 14.2748M3.92258 14.2748C3.46453 14.9894 2.71407 15.658 1.55483 15.8321M8.96262 7.65662V17L11.5553 13.4962H16L8.96262 7.65662Z"
      stroke="#172623"
      strokeLinecap="round"
    />
  </svg>,
  'Lasso'
)

export default LassoIcon
