import { createSvgIcon } from '@mui/material'

const ScaleIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="-3 -3 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.35718 1.00028H13.5C13.7762 1.00028 14 1.22414 14 1.50028V5.64314" stroke="black" />
    <path
      d="M5.64282 14.0003L1.49997 14.0003C1.22382 14.0003 0.999965 13.7764 0.999965 13.5003L0.999966 9.35742"
      stroke="black"
    />
    <path d="M1 5.64286L1 1.5C1 1.22386 1.22386 1 1.5 1L5.64286 1" stroke="black" />
    <path
      d="M14 9.3577L14 13.5006C14 13.7767 13.7761 14.0006 13.5 14.0006L9.35714 14.0006"
      stroke="black"
    />
  </svg>,
  'Scale'
)

export default ScaleIcon
