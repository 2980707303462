import { useNotificationSlice } from 'redux/slices/hooks'
import { NotificationComponent } from 'redux/slices'
import { AlertColor } from '@mui/material'
import _ from 'lodash'
import { errorBundle } from 'utils/constants'
import { useErrorBoundary } from 'react-error-boundary'

export type ErrorResource = {
  severity: AlertColor
  message: string
  component: NotificationComponent
}

export type ReasonCode = keyof typeof errorBundle

export type ErrorBundle = { [reason in ReasonCode]: ErrorResource }

export type ThrowError = {
  reason: ReasonCode
  options?: Partial<ErrorResource>
}

type UseErrorReturn = {
  showError: (reason: ReasonCode, options?: Partial<ErrorResource>) => void
  throwError: (reason: ReasonCode, options?: Partial<ErrorResource>) => void
}

export const useDeepcellError = (): UseErrorReturn => {
  const { showBoundary } = useErrorBoundary<ThrowError>()

  const { showError: showErrorNotification } = useNotificationSlice()
  const showError = (reason: ReasonCode, options?: Partial<ErrorResource>) => {
    if (errorBundle[reason]) {
      const error = _.get(errorBundle, reason)
      const { message, component, severity } = _.defaults(error, options)
      showErrorNotification(message, component, severity)
      return
    }
    const { message, component, severity } = _.defaults(options || {}, errorBundle.DEFAULT)
    showErrorNotification(message, component, severity)
  }

  const throwError = (reason: ReasonCode, options?: Partial<ErrorResource>) => {
    showBoundary({
      reason,
      options,
    })
  }
  return { showError, throwError }
}

export default useDeepcellError
