import { Stack } from '@mui/material'

import { CellVizHeaderLeftSideContent } from './CellVizHeaderLeftSideContent'
import { MorphotypesCompareButtons } from './MorphotypesCompareButtons'
import { PreFilterButtons } from './PreFilterButtons'
import ToolButtons from './ToolButtons'

export const cellVisualizationsHeaderId = 'cellVisualizationsHeaderId'

interface CellVisualizationsHeaderProps {
  isPreFilter?: boolean
}

const CellVisualizationsHeader = ({ isPreFilter }: CellVisualizationsHeaderProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      id={cellVisualizationsHeaderId}
      sx={{
        justifyContent: 'space-between',
        boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
        height: '57px',
        py: 1,
        px: 2,
        alignItems: 'center',
        position: 'relative',
        zIndex: 2,
      }}
    >
      <CellVizHeaderLeftSideContent isPreFilter={isPreFilter} />
      <ToolButtons />
      {isPreFilter ? <PreFilterButtons /> : <MorphotypesCompareButtons />}
    </Stack>
  )
}

export default CellVisualizationsHeader
