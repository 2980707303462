import { SpringValue, useSpring } from '@react-spring/web'

export const useDrawerSpring = (params: {
  drawerWidth: number
  onResolve?: (isNowOpen: boolean) => void
}): {
  toggle: () => void
  buttonSprings: {
    x: SpringValue<number>
  }
  drawerSprings: {
    x: SpringValue<number>
  }
  isOpen: () => boolean
} => {
  const { drawerWidth, onResolve } = params

  const panelWidthNum = drawerWidth + 10
  const panelStartPos = -panelWidthNum
  const buttonStartPos = 0

  const [drawerSprings, drawerApi] = useSpring(() => ({
    from: { x: panelStartPos },
  }))

  const [buttonSprings, buttonApi] = useSpring(() => ({
    from: { x: 0 },
  }))

  const isOpen = () => drawerSprings.x.get() > panelStartPos

  const triggerDrawerMove = () => {
    const open = isOpen()
    drawerApi.start({
      to: {
        x: open ? panelStartPos : panelStartPos + panelWidthNum,
      },
      onResolve: () => onResolve && onResolve(!open),
    })
  }

  const triggerButtonMove = () => {
    buttonApi.start({
      to: {
        x: isOpen() ? buttonStartPos : panelStartPos,
      },
    })
  }

  const toggle = () => {
    triggerDrawerMove()
    triggerButtonMove()
  }

  return { toggle, buttonSprings, drawerSprings, isOpen }
}

export default useDrawerSpring
