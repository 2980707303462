import { SxProps } from '@mui/material'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

type ContentLoadingProps = Pick<CircularProgressProps, 'size'> & {
  sx?: SxProps
}

function ContentLoading({ size = 40,sx }: ContentLoadingProps): JSX.Element {
  return (
    <Grid container direction="column" alignItems="center" sx={{ flexGrow: 1 }}>
      <CircularProgress data-testid="circularProgress" color="secondary" size={size} sx={sx} />
    </Grid>
  )
}

export default ContentLoading
