import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Menu, MenuItem, MenuList, styled } from '@mui/material'
import translatePinnedMorphotypeSelection from 'components/cell-visualizations/translatePinnedMorphotypeSelection'
import useSessionApi from 'components/cell-visualizations/useSessionApi'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import useFlags from 'components/shared/useFlags'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { SaveSessionDialog, SaveSessionDialogProps } from './SaveSessionDialog'

const width = 180

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  color: theme.palette.primary.main,
  width,
  paddingTop: 0,
  paddingBottom: 0,
}))

const StyledMenuItem = styled(MenuItem)({
  justifyContent: 'center',
})

export const SaveSessionMenu = (): JSX.Element => {
  const eventsManager = useEventsManager()

  const { showSaveAs } = useFlags()
  const { setName, setProjectCode, cellVisualizations } = useCellVisualizationsSlice()

  const {
    fileName: cellVizFileName,
    name: nameFromState,
    projectCode: projectCodeFromState,
    cellsData,
  } = cellVisualizations
  const { saveVersionConfig } = useSessionApi()

  const [sessionButtonAnchor, setSessionButtonAnchor] = useState<Element>()
  const sessionButtonOpen = Boolean(sessionButtonAnchor)

  const [saveSessionDialogProps, setSaveSessionDialogProps] = useState<SaveSessionDialogProps>()

  const saveSession = async (name?: string, projectCode?: string, isCopy?: boolean) => {
    // Make sure to wait for save version to finish successfully before proceeding
    // it may encounter an error
    await saveVersionConfig({ name, projectCode, isCopy })

    if (name) setName(name)
    if (projectCode) setProjectCode(projectCode)
  }

  const handleSelectSave = (doSaveAs = false) => {
    setSessionButtonAnchor(undefined)

    if (doSaveAs || nameFromState === 'Untitled Session' || !nameFromState) {
      setSaveSessionDialogProps({
        title: `Save ${doSaveAs ? 'As New ' : ''}Session`,
        defaultName: doSaveAs ? nameFromState : undefined,
        defaultProjectCode: doSaveAs ? projectCodeFromState : undefined,
        isCopy: doSaveAs,
      })
    } else {
      saveSession(nameFromState, projectCodeFromState)
    }
  }

  /** Handle JSON serialization of BigInt values */
  function handleBigInt(_key: string, value: unknown) {
    if (typeof value === 'bigint') {
      return value.toString()
    }
    return value
  }

  /**
   * Download the current session as a JSON file
   *
   * Version history
   *
   * 1.0.0 - Initial release
   * 1.0.1 - Encode BigInt values from Arrow as strings (for any integer columns)
   */
  const handleDownload = () => {
    // reset zoomThresholds because it gets way too big and crashes the download
    const cellAnalysisWithVersion = {
      version: '1.0.1',
      ...{
        ...cellVisualizations,
        pinnedCells: cellVisualizations.pinnedCells?.map((pc) =>
          translatePinnedMorphotypeSelection(pc)
        ),
        zoomThresholds: undefined,
      },
    }

    const blob = new Blob([JSON.stringify(cellAnalysisWithVersion, handleBigInt, 1)], {
      type: 'application/json',
    })
    eventsManager.sendExportEvent(cellVizFileName)
    saveAs(blob, 'cellanalysis.json')
    setSessionButtonAnchor(undefined)
  }

  return (
    <Box>
      <DeepcellPrimaryButton
        contained
        onClick={(e) => setSessionButtonAnchor(e.currentTarget)}
        sx={{ mr: 2, width }}
        color="info"
        endIcon={sessionButtonOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      >
        Save Session
      </DeepcellPrimaryButton>
      <Menu
        anchorEl={sessionButtonAnchor}
        open={sessionButtonOpen}
        onClose={() => setSessionButtonAnchor(undefined)}
      >
        <StyledMenuList>
          <StyledMenuItem key={1} onClick={() => handleSelectSave()}>
            Save
          </StyledMenuItem>
          {showSaveAs === 'yes' ? (
            <StyledMenuItem key={2} onClick={() => handleSelectSave(true)}>
              Save As
            </StyledMenuItem>
          ) : null}
          <StyledMenuItem key={3} disabled={Boolean(!cellsData?.length)} onClick={handleDownload}>
            Export
          </StyledMenuItem>
        </StyledMenuList>
      </Menu>
      <SaveSessionDialog
        {...saveSessionDialogProps}
        open={Boolean(saveSessionDialogProps)}
        onClose={() => setSaveSessionDialogProps(undefined)}
        onSaveSession={(d) => saveSession(d.name, d.projectCode, d.isCopy)}
      />
    </Box>
  )
}

export default SaveSessionMenu
