import { Cell, CellId, Prediction } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { ColDef } from 'ag-grid-community'
import { RowDataType } from 'components/runs/BasicInfoTableConfig'
import { CellResponse } from 'utils/api'
import { formatTimestampToHumanReadable, CELL_TIMEFORMAT } from 'components/shared/date-utils'
import {
  CellClassEncoderDecoder,
  SampleTypeEncoderDecoder,
  getPredictedClass,
} from 'utils/proto-utils'
import CellImageComponent from './CellImageComponent'

export const getCellDetailsColumnDefs: ColDef[] = [
  {
    headerName: 'Label',
    field: 'label',
    flex: 2,
  },
  {
    headerName: 'Values',
    field: 'values',
    flex: 3,
    cellClass: 'ag-value-bold',
  },
]

export const getCellTableColumnDefs: ColDef[] = [
  {
    headerName: '',
    field: 'selected_cell',
    checkboxSelection: true,
    width: 70,
  },
  {
    headerName: 'Cell Image',
    field: 'cell_proto',
    cellRendererFramework: CellImageComponent,
    width: 120,
  },
  {
    headerName: 'Run Id',
    field: 'run_id',
    cellStyle: {
      color: '#5F55D1',
    },
    width: 180,
  },
  {
    headerName: 'Time',
    field: 'time',
    width: 250,
  },
  {
    headerName: 'Cell ID',
    flex: 1,
    field: 'cell_id',
  },
]

export type CellTableRowData = {
  cell_proto: CellResponse
  run_id: string
  cell_id: string
  time: string
}

export const getCellTableRowData = (cells: CellResponse[]): CellTableRowData[] => {
  return cells.map((cellData) => {
    const cellId = cellData?.cell?.getCellId() || new CellId()
    return {
      cell_proto: cellData,
      run_id: cellData?.cell?.getRunId() || '',
      cell_id: `${cellId.getTime()} #${cellId.getNumber()} ${cellId.getInstrumentId()}` || '',
      time: `${formatTimestampToHumanReadable(
        cellData.cell?.getCellId()?.getTime()?.toString(),
        CELL_TIMEFORMAT
      )}`,
    }
  })
}

export const getPredictionValue = (cell: Cell | undefined): string[] | undefined => {
  const predictions = cell?.getPredictionsList()
  let predictionValue
  if (predictions?.length) {
    predictionValue = predictions
      .filter((x) => x.getFrame() === -1)
      .map((p: Prediction) => {
        const cellClassEnum: number | undefined = getPredictedClass(p)
        if (cellClassEnum === undefined) return ''

        const cellClass = CellClassEncoderDecoder.convertToString(cellClassEnum)
        const cellClassProbability = p.getProbabilitiesMap().get(cellClassEnum)
        return `${cellClass} (${Number(cellClassProbability).toFixed(2)})`
      })
  }
  return predictionValue
}

export const getCellDetailsRowData = (
  cell: Cell | undefined,
  cellId: CellId | undefined,
  cellDescription: string | undefined
): RowDataType[] => {
  const imageList = cell?.getImagesList() || []
  return [
    {
      label: 'Run',
      values: cell?.getRunId() || '-',
    },
    {
      label: 'Time',
      values:
        `${formatTimestampToHumanReadable(
          cell?.getCellId()?.getTime()?.toString(),
          CELL_TIMEFORMAT
        )}` || '-',
    },
    {
      label: 'Sample',
      values: cell?.getSample()?.getSampleId() || '-',
    },
    {
      label: 'Mixed Sample',
      values: cell?.getSample()?.getMixedSampleId() || '-',
    },
    {
      label: 'Sample Type',
      values: SampleTypeEncoderDecoder.convertToString(cell?.getSample()?.getSampleType()) || '-',
    },
    {
      label: 'Description',
      values: cellDescription || '-',
    },
    {
      label: 'Number of Images',
      values: cell?.getNumImages() || '-',
    },
    {
      label: 'Image Size',
      values: `${imageList[0]?.getBlobWidth()}x${imageList[0]?.getBlobHeight()}` || '-',
    },
    {
      label: 'Cell Id',
      values: `${cellId?.getTime()} #${cellId?.getNumber()} ${cellId?.getInstrumentId()}` || '-',
    },
    {
      label: 'Predictions',
      values: getPredictionValue(cell) || '-',
    },
  ]
}
