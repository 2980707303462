import { Box } from '@mui/material'
import { CellVisualizationSessionSelection } from './sessionSelectionList'

export const CellVisSelection = (): JSX.Element => {
  return (
    <Box sx={{ overflowY: 'auto', height: 'calc(100% - 48px)', py: 1 }}>
      <CellVisualizationSessionSelection />
    </Box>
  )
}

export default CellVisSelection
