import { useMemo } from 'react'
import { CellPlotData } from 'redux/slices'
import { PlotMarker } from 'plotly.js'
import { DENSITY_ATTRIBUTE } from 'utils/constants'

const usePlotDataWithScale = (
  plotData: CellPlotData[],
  plotDisplayType: string,
  showPreFilter: boolean
): CellPlotData[] => {
  const plotDataWithScale = useMemo(() => {
    if (plotData?.length > 0 && plotDisplayType === DENSITY_ATTRIBUTE && !showPreFilter) {
      // Clone the original plotData
      const updatedData = JSON.parse(JSON.stringify(plotData))
      // Initialize max and min color values
      let maxColor = -Infinity
      let minColor = Infinity
      // Iterate over each object in the plotData array
      updatedData?.forEach((data: CellPlotData) => {
        // Get the color data from the current plotData object
        const colorData = (data?.marker?.color as string[]) || []
        // Calculate max and min values for the current color data
        const maxDataColor = Math.max(...colorData?.map(Number))
        const minDataColor = Math.min(...colorData?.map(Number))
        // Update max and min color values across all objects
        maxColor = Math.max(maxColor, maxDataColor)
        minColor = Math.min(minColor, minDataColor)
      })
      // Update the showscale property of all plotData objects
      updatedData.forEach((data: CellPlotData) => {
        const plotMarker = data?.marker as PlotMarker
        plotMarker.showscale = plotDisplayType === DENSITY_ATTRIBUTE
        plotMarker.colorbar = {
          ...plotMarker.colorbar,
          tickvals: [maxColor, minColor],
          ticktext: ['Max', 'Min'],
        }
      })
      return updatedData
    }
    return plotData
  }, [plotData, plotDisplayType, showPreFilter])

  return plotDataWithScale
}

export default usePlotDataWithScale
