import { Cell } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CellResponse } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'

export interface CellBrowsingState {
  selectedCells: CellResponse[]
  selectedCell: CellResponse
  rowData: CellResponse[]
  totalRowData: number
}

const emptyCellResponse = {
  cell: new Cell(),
  imageUrls: [],
}

export const cellBrowsingInitialState: CellBrowsingState = {
  selectedCells: [],
  selectedCell: emptyCellResponse,
  rowData: [],
  totalRowData: 0,
}

export const cellBrowsingSlice = createSlice({
  name: 'cellBrowsing',
  initialState: cellBrowsingInitialState,
  reducers: {
    resetCellBrowsing: () => {
      return cellBrowsingInitialState
    },
    appendNewCells: (state, action: PayloadAction<CellResponse>) => {
      // only add cell ids that don't already exist, if already exists remove
      const index = state.selectedCells.findIndex((cell) => {
        const cellId = cell.cell.getCellId()
        return messagesEqual(cellId, action.payload.cell.getCellId())
      })

      if (index !== -1) {
        state.selectedCells.splice(index, 1)
      } else {
        state.selectedCells.push(action.payload)
      }

      state.selectedCell = state.selectedCells.at(-1) || emptyCellResponse
    },
    setSelectedCells: (state, action: PayloadAction<CellResponse[]>) => {
      state.selectedCells = action.payload
      state.selectedCell = state.selectedCells.at(-1) || emptyCellResponse
    },
    clearSelectedCellIds: (state) => {
      state.selectedCells = []
      state.selectedCell = emptyCellResponse
    },
    clearRowData: (state) => {
      state.rowData = []
    },
    setTotalRowData: (state, action: PayloadAction<number>) => {
      state.totalRowData = action.payload
    },
    setRowData: (state, action: PayloadAction<CellResponse[] | undefined>) => {
      state.rowData = action.payload || []
    },
    appendRowData: (state, action: PayloadAction<CellResponse[]>) => {
      const cellRow = action.payload[0]
      const index = state.rowData.findIndex((cell) => {
        const cellId = cell.cell.getCellId()
        return messagesEqual(cellId, cellRow.cell.getCellId())
      })

      // Append when data doesn't exists, skip if it does
      if (index === -1) {
        state.rowData.push(...action.payload)
      }
    },
  },
})

export const CellBrowsingActions = cellBrowsingSlice.actions
export const CellBrowsingReducer = cellBrowsingSlice.reducer
